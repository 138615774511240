import React from "react";

const OdsActivity5 = () => {
  return (
    <div className="ods-activities-container">
      <h2>ODS ACTIVIDAD 5:</h2>
      <div className="ods-activity-card">
        <div className="ods-activity-image">
          <img src="https://res.cloudinary.com/dd8a6qc45/image/upload/v1722645233/Volunteers/assets/ODS/2_bwktp1.png" alt="Numero ODS" />
        </div>
        <p>
          <span>ODS 2: Hambre Cero: (Meta 2.3) </span> <br />
          Crear un huerto casero mejora la seguridad alimentaria al proporcionar
          acceso a alimentos frescos y nutritivos. Esto puede ayudar a reducir
          la dependencia de los mercados y aumentar la autosuficiencia
          alimentaria.
        </p>
      </div>
      <div className="ods-activity-card">
        <div className="ods-activity-image">
          <img src="https://res.cloudinary.com/dd8a6qc45/image/upload/v1722645231/Volunteers/assets/ODS/3_tqqywb.png" alt="Numero ODS" />
        </div>
        <p>
          <span>ODS 3: Salud y Bienestar: (Meta 3.9) </span> <br />
          Cultivar tus propios alimentos puede reducir la exposición a pesticidas y otros productos químicos peligrosos que a menudo se utilizan en la agricultura comercial. Los huertos caseros pueden proporcionar alimentos más frescos y saludables, contribuyendo a una mejor nutrición y bienestar general.
        </p>
      </div>
      <div className="ods-activity-card">
        <div className="ods-activity-image">
          <img src="https://res.cloudinary.com/dd8a6qc45/image/upload/v1722645231/Volunteers/assets/ODS/4_xqio0d.png" alt="Numero ODS" />
        </div>
        <p>
          <span>ODS 4: Educación de Calidad: (Meta 4.7) </span> <br />
          Un huerto casero puede ser una herramienta educativa para enseñar a los niños y adultos sobre la agricultura, la sostenibilidad y la importancia de una alimentación saludable, fomentando el aprendizaje práctico y la concienciación ambiental.
        </p>
      </div>
      <div className="ods-activity-card">
        <div className="ods-activity-image">
          <img src="https://res.cloudinary.com/dd8a6qc45/image/upload/v1722645225/Volunteers/assets/ODS/11_qyelnc.png" alt="Numero ODS" />
        </div>
        <p>
          <span>ODS 11: Ciudades y Comunidades Sostenibles: (Meta 11.7) </span> <br />
          Los huertos caseros contribuyen a la creación de comunidades más verdes y sostenibles, mejorando la calidad del aire y proporcionando espacios de recreación y aprendizaje en entornos urbanos y rurales.
        </p>
      </div>
      <div className="ods-activity-card">
        <div className="ods-activity-image">
          <img src="https://res.cloudinary.com/dd8a6qc45/image/upload/v1722645225/Volunteers/assets/ODS/12_totmyp.png" alt="Numero ODS" />
        </div>
        <p>
          <span>ODS 12: Producción y Consumo Responsables: (Meta 12.3, 12.5) </span> <br />
          Reutilizar frutas y vegetales para germinarlos y crear un huerto casero reduce el desperdicio de alimentos y promueve un ciclo de producción y consumo más sostenible. Esta práctica también disminuye la cantidad de residuos orgánicos que terminan en vertederos.
        </p>
      </div>
      <div className="ods-activity-card">
        <div className="ods-activity-image">
          <img src="https://res.cloudinary.com/dd8a6qc45/image/upload/v1722645225/Volunteers/assets/ODS/13_tmtnkh.png" alt="Numero ODS" />
        </div>
        <p>
          <span>ODS 13: Acción por el Clima: (Meta 13.3) </span> <br />
          La agricultura urbana y los huertos caseros pueden contribuir a la mitigación del cambio climático al reducir la necesidad de transporte de alimentos, lo que disminuye las emisiones de gases de efecto invernadero. Además, estas prácticas pueden educar a las personas sobre la importancia de la sostenibilidad y la adaptación al cambio climático.
        </p>
      </div>
      <div className="ods-activity-card">
        <div className="ods-activity-image">
          <img src="https://res.cloudinary.com/dd8a6qc45/image/upload/v1722645225/Volunteers/assets/ODS/15_hd4qmx.png" alt="Numero ODS" />
        </div>
        <p>
          <span>ODS 15: Vida de Ecosistemas Terrestres: (Meta 15.1) </span> <br />
          Los huertos caseros fomentan la biodiversidad y el uso sostenible de los recursos naturales. Al cultivar una variedad de plantas, se puede mejorar la salud del suelo y crear hábitats para la vida silvestre local, contribuyendo a la conservación de los ecosistemas terrestres.
        </p>
      </div>
      <div className="ods-activity-card">
        <div className="ods-activity-image">
          <img src="https://res.cloudinary.com/dd8a6qc45/image/upload/v1722645225/Volunteers/assets/ODS/17_e8x3cm.png" alt="Numero ODS" />
        </div>
        <p>
          <span>ODS 17: Alianzas para lograr los objetivos: (Meta 17.17) </span> <br />
          Empezar un huerto casero puede involucrar la colaboración entre vecinos, comunidades, organizaciones no gubernamentales y gobiernos locales. Estas alianzas pueden fortalecer los esfuerzos comunitarios para promover la sostenibilidad y compartir conocimientos y recursos.
        </p>
      </div>
    </div>
  );
};

export default OdsActivity5;
