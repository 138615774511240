import { tareasActions } from "../actions/tareasActions";

export const initialState = {
  isLoading: false,
  tareas: [],
  tareaId: null,
  tareaRand: [],
  tareasRandom: [],
  isLoadingRand: false,
  task18: null,
  errorMessage: "",
};

export const tareasReducer = (state, actions) => {
  const { type, payload } = actions;

  switch (type) {
    case tareasActions.LOAD_TAREAS:
      return {
        ...state,
        isLoading: true,
      };
    case tareasActions.LOAD_TAREAS_SUCCESS:
      return {
        ...state,
        tareas: payload,
        isLoading: false,
      };
    case tareasActions.LOAD_TAREAS_ERROR:
      return {
        ...state,
        isLoading: false,
        errorMessage: payload,
      };
    case tareasActions.SAVE_TAREAS:
      return {
        ...state,
        isLoading: true,
      };
    case tareasActions.SAVE_TAREAS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        tareas: [...state.tareas, payload],
      };
    case tareasActions.SAVE_TAREAS_ERROR:
      return {
        ...state,
        isLoading: false,
        errorMessage: payload,
      };
    case tareasActions.DELETE_TAREA:
      return {
        ...state,
        isLoading: true,
      };
    case tareasActions.DELETE_TAREA_SUCCESS:
      return {
        ...state,
        tareas: state.tareas.filter((tarea) => tarea._id !== payload),
        isLoading: false,
      };
    case tareasActions.DELETE_TAREA_ERROR:
      return {
        ...state,
        isLoading: false,
        errorMessage: payload,
      };
    case tareasActions.UPDATE_TAREA:
      return {
        ...state,
        isLoading: true,
      };
    case tareasActions.UPDATE_TAREA_SUCCESS:
      return {
        ...state,
        tareaId: payload,
        isLoading: false,
      };
    case tareasActions.UPDATE_TAREA_ERROR:
      return {
        ...state,
        isLoading: false,
        errorMessage: payload,
      };
    case tareasActions.GET_TAREA:
      return {
        ...state,
        isLoading: true,
      };
    case tareasActions.GET_TAREA_SUCCESS:
      return {
        ...state,
        tareaId: payload,
        isLoading: false,
      };
    case tareasActions.GET_TAREA_ERROR:
      return {
        ...state,
        isLoading: false,
        errorMessage: payload,
      };
    case tareasActions.GET_TAREA_RANDOM:
      return {
        ...state,
        isLoading: true,
      };
    case tareasActions.GET_TAREA_RANDOM_SUCCESS:
      return {
        ...state,
        tareaRand: payload,
        isLoading: false,
      };
    case tareasActions.GET_TAREA_RANDOM_ERROR:
      return {
        ...state,
        isLoading: false,
        errorMessage: payload,
      };
      case tareasActions.GET_TAREAS_RANDOM:
      return {
        ...state,
        isLoading: true,
      };
    case tareasActions.GET_TAREAS_RANDOM_SUCCESS:
      return {
        ...state,
        tareasRandom: payload,
        isLoading: false,
      };
    case tareasActions.GET_TAREAS_RANDOM_ERROR:
      return {
        ...state,
        isLoading: false,
        errorMessage: payload,
      };
      case tareasActions.GET_TASK18:
      return {
        ...state,
        isLoading: true,
      };
    case tareasActions.GET_TASK18_SUCCESS:
      return {
        ...state,
        task18: payload,
        isLoading: false,
      };
    case tareasActions.GET_TASK18_ERROR:
      return {
        ...state,
        isLoading: false,
        errorMessage: payload,
      };
    default:
      return state;
  }
};
