import React from "react";

const OdsActivity14 = () => {
  return (
    <div className="ods-activities-container">
      <h2>ODS ACTIVIDAD 14:</h2>
      <div className="ods-activity-card">
        <div className="ods-activity-image">
          <img
            src="https://res.cloudinary.com/dd8a6qc45/image/upload/v1722645233/Volunteers/assets/ODS/2_bwktp1.png"
            alt="Numero ODS"
          />
        </div>
        <p>
          <span>ODS 2: Hambre cero: (Meta 2.4) </span> <br />
          Promover dietas más sostenibles puede liberar recursos (como tierras y
          agua) para la producción de alimentos vegetales, que pueden ser más
          eficientes en términos de uso de recursos y pueden contribuir a la
          seguridad alimentaria global.
        </p>
      </div>
      <div className="ods-activity-card">
        <div className="ods-activity-image">
          <img
            src="https://res.cloudinary.com/dd8a6qc45/image/upload/v1722645231/Volunteers/assets/ODS/3_tqqywb.png"
            alt="Numero ODS"
          />
        </div>
        <p>
          <span>ODS 3: Salud y bienestar: (Meta 3.4) </span> <br />
          Adoptar una dieta más basada en plantas y reducir el consumo de carne
          puede mejorar la salud cardiovascular y reducir el riesgo de
          enfermedades no transmisibles como la diabetes y ciertos tipos de
          cáncer, contribuyendo al bienestar general.
        </p>
      </div>
      <div className="ods-activity-card">
        <div className="ods-activity-image">
          <img
            src="https://res.cloudinary.com/dd8a6qc45/image/upload/v1722645225/Volunteers/assets/ODS/12_totmyp.png"
            alt="Numero ODS"
          />
        </div>
        <p>
          <span>
            ODS 12: Producción y consumo responsables: (Meta 12.2, 12.3){" "}
          </span>{" "}
          <br />
          La producción de carne consume más recursos naturales (agua, tierra,
          energía) que la producción de alimentos vegetales. Reducir el consumo
          de carne ayuda a gestionar de manera más sostenible estos recursos.
          Además, al disminuir la demanda de productos cárnicos, se reduce el
          desperdicio asociado a su producción y distribución.
        </p>
      </div>
      <div className="ods-activity-card">
        <div className="ods-activity-image">
          <img
            src="https://res.cloudinary.com/dd8a6qc45/image/upload/v1722645225/Volunteers/assets/ODS/13_tmtnkh.png"
            alt="Numero ODS"
          />
        </div>
        <p>
          <span>ODS 13: Acción por el clima: (Meta 13.2) </span> <br />
          La ganadería es una fuente significativa de emisiones de gases de
          efecto invernadero, incluyendo metano y dióxido de carbono. Reducir el
          consumo de carne contribuye a disminuir estas emisiones, ayudando a
          mitigar el cambio climático.
        </p>
      </div>
      <div className="ods-activity-card">
        <div className="ods-activity-image">
          <img
            src="https://res.cloudinary.com/dd8a6qc45/image/upload/v1722645225/Volunteers/assets/ODS/14_mbd8kd.png"
            alt="Numero ODS"
          />
        </div>
        <p>
          <span>ODS 14: Vida submarina: (Meta 14.1) </span> <br />
          Reducir el consumo de pescado puede aliviar la presión sobre las
          poblaciones de peces y los ecosistemas marinos, ayudando a prevenir la
          sobrepesca y promover la conservación de la biodiversidad marina.
        </p>
      </div>
      <div className="ods-activity-card">
        <div className="ods-activity-image">
          <img
            src="https://res.cloudinary.com/dd8a6qc45/image/upload/v1722645225/Volunteers/assets/ODS/15_hd4qmx.png"
            alt="Numero ODS"
          />
        </div>
        <p>
          <span>ODS 15: Vida de ecosistemas terrestres: (Meta 15.1) </span>{" "}
          <br />
          La producción de carne implica la deforestación para pastos y cultivos
          de forraje. Reducir el consumo de carne puede disminuir la presión
          sobre los bosques y otros ecosistemas terrestres, contribuyendo a su
          conservación y restablecimiento.
        </p>
      </div>
    </div>
  );
};

export default OdsActivity14;
