export const commentsActions = {
  LOAD_COMMENTS: "LOAD_COMMENTS",
  LOAD_COMMENTS_SUCCESS: "LOAD_COMMENTS_SUCCESS",
  LOAD_COMMENTS_ERROR: "LOAD_COMMENTS_ERROR",

  GET_COMMENT: "GET_COMMENT",
  GET_COMMENT_SUCCESS: "GET_COMMENT_SUCCESS",
  GET_COMMENT_ERROR: "GET_COMMENT_ERROR",

  GET_COMMENTS_TAREA: "GET_COMMENTS_TAREA",
  GET_COMMENTS_TAREA_SUCCESS: "GET_COMMENTS_TAREA_SUCCESS",
  GET_COMMENTS_TAREA_ERROR: "GET_COMMENTS_TAREA_ERROR",

  SAVE_COMMENT: "SAVE_COMMENT",
  SAVE_COMMENT_SUCCESS: "SAVE_COMMENT_SUCCESS",
  SAVE_COMMENT_ERROR: "SAVE_COMMENT_ERROR",

  UPDATE_COMMENT: "UPDATE_COMMENT",
  UPDATE_COMMENT_SUCCESS: "UPDATE_COMMENT_SUCCESS",
  UPDATE_COMMENT_ERROR: "UPDATE_COMMENT_ERROR",

  DELETE_COMMENT: "DELETE_COMMENT",
  DELETE_COMMENT_SUCCESS: "DELETE_COMMENT_SUCCESS",
  DELETE_COMMENT_ERROR: "DELETE_COMMENT_ERROR",

  CLEAN_COMMENT: "CLEAN_COMMENT",
  CLEAN_COMMENT_SUCCESS: "CLEAN_COMMENT_SUCCESS",
  CLEAN_COMMENT_ERROR: "CLEAN_COMMENT_ERROR",
};
