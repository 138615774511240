import { messagesActions } from "../actions/messagesActions";

export const initialState = {
  isLoading: false,
  isLoadingChatHistory: false,
  messages: [],
  messagesUser: [],
  messageId: null,
  errorMessage: "",
  newMessage: null,
  viewChat: false,
};

export const messagesReducer = (state, actions) => {
  const { type, payload } = actions;

  switch (type) {
    case messagesActions.LOAD_MESSAGES:
      return {
        ...state,
        isLoading: true,
      };
    case messagesActions.LOAD_MESSAGES_SUCCESS:
      return {
        ...state,
        messages: payload,
        isLoading: false,
      };
    case messagesActions.LOAD_MESSAGES_ERROR:
      return {
        ...state,
        errorMessage: payload,
        isLoading: false,
      };
    case messagesActions.GET_MESSAGE:
      return {
        ...state,
        isLoading: true,
      };
    case messagesActions.GET_MESSAGE_SUCCESS:
      return {
        ...state,
        messageId: payload,
        isLoading: false,
      };
    case messagesActions.GET_MESSAGE_ERROR:
      return {
        ...state,
        errorMessage: payload,
        isLoading: false,
      };
    case messagesActions.SAVE_MESSAGE:
      return {
        ...state,
        // isLoading: true,
      };
    case messagesActions.SAVE_MESSAGE_SUCCESS:
      return {
        ...state,
        messages: [...state.messages, payload.messages],
        newMessage: payload.newMessage,
        isLoading: false,
      };
    case messagesActions.SAVE_MESSAGE_ERROR:
      return {
        ...state,
        errorMessage: payload,
        isLoading: false,
      };
    case messagesActions.UPDATE_MESSAGE:
      return {
        ...state,
        isLoading: true,
      };
    case messagesActions.UPDATE_MESSAGE_SUCCESS:
      return {
        ...state,
        messageId: payload,
        isLoading: false,
      };
    case messagesActions.UPDATE_MESSAGE_ERROR:
      return {
        ...state,
        errorMessage: payload,
        isLoading: false,
      };
    case messagesActions.REMOVE_MESSAGE:
      return {
        ...state,
        isLoading: true,
      };
    case messagesActions.REMOVE_MESSAGE_SUCCESS:
      return {
        ...state,
        messages: state.messages.filter((message) => message._id !== payload),
        isLoading: false,
      };
    case messagesActions.REMOVE_MESSAGE_ERROR:
      return {
        ...state,
        errorMessage: payload,
        isLoading: false,
      };
      case messagesActions.LOAD_MESSAGES_USER:
      return {
        ...state,
        isLoadingChatHistory: true,
      };
    case messagesActions.LOAD_MESSAGES_USER_SUCCESS:
      return {
        ...state,
        messagesUser: payload,
        isLoadingChatHistory: false,
      };
    case messagesActions.LOAD_MESSAGES_USER_ERROR:
      return {
        ...state,
        errorMessage: payload,
        isLoadingChatHistory: false,
      };
      case messagesActions.VIEW_CHAT:
      return {
        ...state,
        isLoading: true,
      };
    case messagesActions.VIEW_CHAT_SUCCESS:
      return {
        ...state,
        viewChat: payload,
        isLoading: false,
      };
    case messagesActions.VIEW_CHAT_ERROR:
      return {
        ...state,
        errorMessage: payload,
        isLoading: false,
      };
    default: {
      return {
        ...state,
      };
    }
  }
};
