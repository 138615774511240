import { userActions } from "../actions/userActions";

const token = localStorage.getItem("token");
const user = JSON.parse(localStorage.getItem("user"));

const admin = user?.roles.some((role) => role.name === "admin");

export const initialState = {
  isLoading: false,
  isLoadingChat: false,
  isLoggedIn: Boolean(token),
  errorMessage: "",
  errorMessagePassword: "",
  user: user || null,
  allUsers: [],
  token: token || "",
  userTareas: [],
  userId: null,
  userIdChat: null,
  isAdmin: admin || null,
  adminNoteUser: "",
  emailResetPass: "",
  userPasswordReset: null,
  socket: "",

};

export const userReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case userActions.DELETE_USER_ADMIN:
      return {
        ...state,
        isLoading: true,
      };
    case userActions.DELETE_USER_ADMIN_SUCCESS:
      return {
        ...state,
        allUsers: state.allUsers.filter((user) => user._id !== payload),
        isLoading: false,
      };
    case userActions.DELETE_USER_ADMIN_ERROR:
      return {
        ...state,
        isLoading: false,
        errorMessage: payload,
      };
    case userActions.USER_SIGNUP:
      return {
        ...state,
        isLoading: true,
      };
    case userActions.USER_SIGNUP_SUCCESS:
      return {
        ...state,
        isLoading: false,
        token: payload.token,
        user: payload.user,
        isAdmin: payload.isAdmin,
        isLoggedIn: true,
        errorMessage: null,
      };
    case userActions.USER_SIGNUP_ERROR:
      return {
        ...state,
        isLoading: false,
        errorMessage: payload,
      };
    case userActions.USER_SIGNIN:
      return {
        ...state,
        isLoading: true,
      };
    case userActions.USER_SIGNIN_SUCCESS:
      return {
        ...state,
        isLoading: false,
        token: payload.token,
        user: payload.user,
        socket: payload.socket,
        isLoggedIn: true,
        errorMessage: null,
      };
    case userActions.USER_SIGNIN_ERROR:
      return {
        ...state,
        isLoading: false,
        errorMessage: payload,
      };
    case userActions.GET_USER_ID:
      return {
        ...state,
        isLoading: true,
      };
    case userActions.GET_USER_ID_SUCCESS:
      return {
        ...state,
        userId: payload,
        isLoading: false,
        errorMessage: null,
      };
    case userActions.GET_USER_ID_ERROR:
      return {
        ...state,
        isLoading: false,
        errorMessage: payload,
      };
    case userActions.GET_USERS:
      return {
        ...state,
        isLoading: true,
      };
    case userActions.GET_USERS_SUCCESS:
      return {
        ...state,
        allUsers: payload,
        isLoading: false,
        errorMessage: null,
      };
    case userActions.GET_USERS_ERROR:
      return {
        ...state,
        isLoading: false,
        errorMessage: payload,
      };

    case userActions.USER_LOGOUT:
      return {
        ...state,
        isLoading: false,
        errorMessage: null,
        user: null,
        token: null,
        socket: "",
        isLoggedIn: false,
      };
    case userActions.USER_UPDATE:
      return {
        ...state,
        isLoading: true,
      };
    case userActions.USER_UPDATE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        user: payload,
        errorMessage: null,
      };
    case userActions.USER_UPDATE_ERROR:
      return {
        ...state,
        isLoading: false,
        errorMessage: payload,
      };
    case userActions.LOAD_TAREAS_USER:
      return {
        ...state,
        isLoading: true,
      };
    case userActions.LOAD_TAREAS_USER_SUCCESS:
      return {
        ...state,
        userTareas: payload.userTareas,
        isLoading: false,
      };
    case userActions.LOAD_TAREAS_USER_ERROR:
      return {
        ...state,
        isLoading: false,
        errorMessage: payload,
      };
    case userActions.SEND_REVIEW:
      return {
        ...state,
        isLoading: true,
      };
    case userActions.SEND_REVIEW_SUCCESS:
      return {
        ...state,
        user: payload,
        isLoading: false,
      };
    case userActions.SEND_REVIEW_ERROR:
      return {
        ...state,
        isLoading: false,
        errorMessage: payload,
      };

    case userActions.USER_UPDATE_ADMIN:
      return {
        ...state,
        isLoading: true,
      };
    case userActions.USER_UPDATE_ADMIN_SUCCESS:
      return {
        ...state,
        isLoading: false,
        userId: payload,
        errorMessage: null,
      };
    case userActions.USER_UPDATE_ADMIN_ERROR:
      return {
        ...state,
        isLoading: false,
        errorMessage: payload,
      };
    case userActions.SEND_EMAIL_PASSWORD:
      return {
        ...state,
        isLoading: true,
      };
    case userActions.SEND_EMAIL_PASSWORD_SUCCESS:
      return {
        ...state,
        emailResetPass: payload,
        isLoading: false,
        errorMessage: null,
      };
    case userActions.SEND_EMAIL_PASSWORD_ERROR:
      return {
        ...state,
        errorMessage: payload,
        isLoading: false,
      };
    case userActions.PASSWORD_RESET:
      return {
        ...state,
        isLoading: true,
      };
    case userActions.PASSWORD_RESET_SUCCESS:
      return {
        ...state,
        passwordReset: payload,
        isLoading: false,
        errorMessagePassword: null,
      };
    case userActions.PASSWORD_RESET_ERROR:
      return {
        ...state,
        errorMessagePassword: payload,
        isLoading: false,
      };
    case userActions.UPDATE_PASSWORD:
      return {
        ...state,
        isLoading: true,
      };
    case userActions.UPDATE_PASSWORD_SUCCESS:
      return {
        ...state,
        userId: payload,
        isLoading: false,
        errorMessage: null,
      };
    case userActions.UPDATE_PASSWORD_ERROR:
      return {
        ...state,
        errorMessage: payload,
        isLoading: false,
      };
    case userActions.UPDATE_MYUSER:
      return {
        ...state,
        isLoading: true,
      };
    case userActions.UPDATE_MYUSER_SUCCESS:
      return {
        ...state,
        user: payload,
        isLoading: false,
        errorMessage: null,
      };
    case userActions.UPDATE_MYUSER_ERROR:
      return {
        ...state,
        errorMessage: payload,
        isLoading: false,
      };
    case userActions.DELETE_MY_ACCOUNT:
      return {
        ...state,
        isLoading: true,
      };
    case userActions.DELETE_MY_ACCOUNT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        errorMessage: null,
      };
    case userActions.DELETE_MY_ACCOUNT_ERROR:
      return {
        ...state,
        errorMessage: payload,
        isLoading: false,
      };
      case userActions.GET_USER_CHAT:
      return {
        ...state,
        isLoadingChat: true,
      };
    case userActions.GET_USER_CHAT_SUCCESS:
      return {
        ...state,
        userIdChat: payload,
        isLoadingChat: false,
        errorMessage: null,
      };
    case userActions.GET_USER_CHAT_ERROR:
      return {
        ...state,
        errorMessage: payload,
        isLoadingChat: false,
      };
    default:
      return state;
  }
};
