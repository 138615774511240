import { activitiesActions } from "../actions/activitiesActions";

export const initialState = {
  isLoading: false,
  activities: [],
  activityId: null,
  errorMessage: "",
};

export const activitiesReducer = (state, actions) => {
  const { type, payload } = actions;

  switch (type) {
    case activitiesActions.LOAD_ACTIVITIES:
      return {
        ...state,
        isLoading: true,
      };
    case activitiesActions.LOAD_ACTIVITIES_SUCCESS:
      return {
        ...state,
        activities: payload,
        isLoading: false,
      };
    case activitiesActions.LOAD_ACTIVITIES_ERROR:
      return {
        ...state,
        isLoading: false,
        errorMessage: payload,
      };
    case activitiesActions.SAVE_ACTIVITIES:
      return {
        ...state,
        isLoading: true,
      };
    case activitiesActions.SAVE_ACTIVITIES_SUCCESS:
      return {
        ...state,
        activities: [...state.activities, payload],
        isLoading: false,
      };
    case activitiesActions.SAVE_ACTIVITIES_ERROR:
      return {
        ...state,
        isLoading: false,
        errorMessage: payload,
      };
    case activitiesActions.DELETE_ACTIVITIES:
      return {
        ...state,
        isLoading: true,
      };
    case activitiesActions.DELETE_ACTIVITIES_SUCCESS:
      return {
        ...state,
        activities: state.activities.filter(
          (activity) => activity._id !== payload
        ),
        isLoading: false,
      };
    case activitiesActions.DELETE_ACTIVITIES_ERROR:
      return {
        ...state,
        isLoading: false,
        errorMessage: payload,
      };
    case activitiesActions.UPDATE_ACTIVITIES:
      return {
        ...state,
        isLoading: true,
      };
    case activitiesActions.UPDATE_ACTIVITIES_SUCCESS:
      return {
        ...state,
        activityId: payload,
        isLoading: false,
      };
    case activitiesActions.UPDATE_ACTIVITIES_ERROR:
      return {
        ...state,
        isLoading: false,
        errorMessage: payload,
      };
    case activitiesActions.GET_ACTIVITIES:
      return {
        ...state,
        isLoading: true,
      };
    case activitiesActions.GET_ACTIVITIES_SUCCESS:
      return {
        ...state,
        activityId: payload,
        isLoading: false,
      };
    case activitiesActions.GET_ACTIVITIES_ERROR:
      return {
        ...state,
        isLoading: false,
        errorMessage: payload,
      };

    default:
      return state;
  }
};
