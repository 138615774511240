import React, { useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

import { useUser } from "../../context/providers/userContext";
import { Spinner } from "../../components/ui/Spinner";

import "../../styles/perfil.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAnglesLeft, faCheck } from "@fortawesome/free-solid-svg-icons";

const Perfil = () => {
  const { user, isLoading, updateUser } = useUser();
  const navigate = useNavigate();
  const params = useParams();
  const [us, setUs] = useState();
  const [imagePreview, setImagePreview] = useState("");

  const handleChange = (e) => {
    if (e.target.name === "image") {
      const file = e.target.files[0];
      setUs({ ...us, [e.target.name]: e.target.files[0] });
      setImagePreview(URL.createObjectURL(file));
    } else {
      setUs({ ...us, [e.target.name]: e.target.value });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const userResponse = await updateUser(params.id, us);
      if (userResponse) {
        navigate(-1);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <div className="body">
        <main className="main">
          {isLoading ? (
            Spinner
          ) : (
            <>
              <section className="section-perfil">
                <div className="perfil-img">
                  <img
                    className="detail-image"
                    src={
                      imagePreview ||
                      (user && user.image.url) ||
                      "https://res.cloudinary.com/dd8a6qc45/image/upload/v1690638814/Volunteers/perfilImage/fuonicgkazr1m9bntyet.svg"
                    }
                    alt="Imagen"
                  />
                </div>
                <div className="form">
                  <form onSubmit={handleSubmit}>
                    <div className="input-label-form">
                      <label htmlFor="image">Imagen</label>
                      <input
                        className="second-buttom input-image-file"
                        name="image"
                        type="file"
                        onChange={handleChange}
                      />
                    </div>
                    <div className="buttons-perfil">
                      <Link
                        className="buttom-back-profile"
                        onClick={() => {
                          navigate(-1);
                        }}
                      >
                        <FontAwesomeIcon icon={faAnglesLeft} />
                      </Link>

                      <button className="buttom-send-profile" type="submit">
                        {isLoading ? (
                          "Cargando..."
                        ) : (
                          <FontAwesomeIcon icon={faCheck} />
                        )}
                      </button>
                    </div>
                  </form>
                </div>
              </section>
            </>
          )}
        </main>
      </div>
    </div>
  );
};

export default Perfil;
