import React from "react";
import "../../styles/dashboard.css";

const VideosDashboard = () => {
  return (
    <div className="videosDashboard-container">
      <p>Galeria de videos</p>
    </div>
  );
};

export default VideosDashboard;
