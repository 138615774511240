import React from "react";

const OdsActivity9 = () => {
  return (
    <div className="ods-activities-container">
      <h2>ODS ACTIVIDAD 9:</h2>
      <div className="ods-activity-card">
        <div className="ods-activity-image">
          <img
            src="https://res.cloudinary.com/dd8a6qc45/image/upload/v1722645231/Volunteers/assets/ODS/3_tqqywb.png"
            alt="Numero ODS"
          />
        </div>
        <p>
          <span>ODS 3: Salud y Bienestar: (Meta 3.4) </span> <br />
          Las caminatas ecológicas fomentan la actividad física, lo que puede
          mejorar la salud cardiovascular y reducir el riesgo de enfermedades.
          Además, estar en contacto con la naturaleza tiene beneficios
          comprobados para la salud mental, reduciendo el estrés y mejorando el
          bienestar general.
        </p>
      </div>
      <div className="ods-activity-card">
        <div className="ods-activity-image">
          <img
            src="https://res.cloudinary.com/dd8a6qc45/image/upload/v1722645231/Volunteers/assets/ODS/4_xqio0d.png"
            alt="Numero ODS"
          />
        </div>
        <p>
          <span>ODS 4: Educación de Calidad: (Meta 4.7) </span> <br />
          Estas caminatas pueden incluir componentes educativos sobre la
          biodiversidad, la ecología y la conservación, proporcionando
          aprendizaje práctico y concienciación ambiental a los participantes.
        </p>
      </div>
      <div className="ods-activity-card">
        <div className="ods-activity-image">
          <img
            src="https://res.cloudinary.com/dd8a6qc45/image/upload/v1722645225/Volunteers/assets/ODS/11_qyelnc.png"
            alt="Numero ODS"
          />
        </div>
        <p>
          <span>DS 11: Ciudades y Comunidades Sostenibles: (Meta 11.7) </span>{" "}
          <br />
          Promover caminatas ecológicas en áreas urbanas y rurales fomenta el
          uso de espacios verdes, mejora la calidad de vida y fortalece el
          sentido de comunidad y la conexión con el entorno natural.
        </p>
      </div>
      <div className="ods-activity-card">
        <div className="ods-activity-image">
          <img
            src="https://res.cloudinary.com/dd8a6qc45/image/upload/v1722645225/Volunteers/assets/ODS/12_totmyp.png"
            alt="Numero ODS"
          />
        </div>
        <p>
          <span>ODS 12: Producción y Consumo Responsables: (Meta 12.8) </span>{" "}
          <br />
          Participar en caminatas ecológicas puede aumentar la conciencia sobre
          la importancia de un consumo y producción responsables, al conectar a
          las personas directamente con la naturaleza y enseñarles sobre los
          impactos de sus acciones en el medio ambiente.
        </p>
      </div>
      <div className="ods-activity-card">
        <div className="ods-activity-image">
          <img
            src="https://res.cloudinary.com/dd8a6qc45/image/upload/v1722645225/Volunteers/assets/ODS/13_tmtnkh.png"
            alt="Numero ODS"
          />
        </div>
        <p>
          <span>ODS 13: Acción por el Clima: (Meta 13.3) </span> <br />
          Participar en caminatas ecológicas puede aumentar la conciencia sobre
          el cambio climático y la necesidad de proteger el medio ambiente.
        </p>
      </div>
      <div className="ods-activity-card">
        <div className="ods-activity-image">
          <img
            src="https://res.cloudinary.com/dd8a6qc45/image/upload/v1722645225/Volunteers/assets/ODS/15_hd4qmx.png"
            alt="Numero ODS"
          />
        </div>
        <p>
          <span>ODS 15: Vida de Ecosistemas Terrestres: (Meta 15.1) </span>{" "}
          <br />
          Al realizar caminatas ecológicas, los participantes pueden aprender
          sobre la importancia de conservar los ecosistemas y la biodiversidad,
          fomentando un mayor respeto y compromiso hacia la protección del medio
          ambiente.
        </p>
      </div>
      <div className="ods-activity-card">
        <div className="ods-activity-image">
          <img
            src="https://res.cloudinary.com/dd8a6qc45/image/upload/v1722645225/Volunteers/assets/ODS/17_e8x3cm.png"
            alt="Numero ODS"
          />
        </div>
        <p>
          <span>ODS 17: Alianzas para lograr los objetivos: (Meta 17.17) </span>{" "}
          <br />
          Las caminatas ecológicas pueden involucrar a diferentes actores, como
          gobiernos locales, organizaciones no gubernamentales y comunidades,
          fomentando alianzas y colaboraciones para promover la sostenibilidad y
          la protección del medio ambiente.
        </p>
      </div>
    </div>
  );
};

export default OdsActivity9;
