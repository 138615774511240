import React, { useEffect, useRef } from "react";

import "../styles/presentation.css";

const Presentation = () => {
  const containerRef = useRef(null);
  const handleScroll = (e) => {
    e.preventDefault();
    var scrollAmount = 0;
    if (window.innerWidth < 1024) {
      scrollAmount = window.innerHeight - 102;
    } else {
      scrollAmount = window.innerHeight - 148;
    }
    const currentScroll = window.scrollY;

    if (e.deltaY > 0) {
      window.scrollTo({
        top: currentScroll + scrollAmount,
        behavior: "smooth",
      });
    } else {
      window.scrollTo({
        top: currentScroll - scrollAmount,
        behavior: "smooth",
      });
    }
  };

  useEffect(() => {
    const options = {
      passive: false,
    };
    window.addEventListener("wheel", handleScroll, options);

    return () => {
      window.removeEventListener("wheel", handleScroll, options);
    };
  }, []);

  return (
    <div className="presentation-container">
      <div className="presentation-article-1">
        <div className="article-1-left">
          <div className="img-container-presentation">
            <img
              src="https://res.cloudinary.com/dd8a6qc45/image/upload/v1710177344/Volunteers/assets/TRIANGULO-NEGRO-VOLUNTEERS-1080p_qllgq2.png"
              alt="Logo Volunteers"
            />
          </div>
          <div className="text-article-1-left">
            <h2>Sistema holistico de activismo ambiental</h2>
            <p>Entrenamiento de Voluntariado y Coaching ambiental</p>
          </div>
        </div>
        <div className="article-1-right">
          <div className="img-container-background-presentation">
            <img
              src="https://cdn.pixabay.com/photo/2024/06/25/13/01/plant-8852648_1280.png"
              alt="Fondo plantas"
            />
          </div>
          <div className="text-article-1-right">
            <div className="text-article-1-right-h2">
              <h2>PLATAFORMA PARA LA SOSTENIBILIDAD Y LA ACCION CLIMATICA</h2>
            </div>
            <div className="supported-container">
              <p>SUPPORTED BY:</p>
              <div className="img-supported-container">
                <img
                  src="https://res.cloudinary.com/dd8a6qc45/image/upload/v1708784141/Volunteers/assets/175_Icono_Volunteers_nio4cr.png"
                  alt="Logo Forum"
                />
                <img
                  src="https://res.cloudinary.com/dd8a6qc45/image/upload/v1723324086/Volunteers/assets/250xSustainable_Development_Goals_logo_b1o6jc.png"
                  alt="Logo SDG"
                />
                <img
                  src="https://res.cloudinary.com/dd8a6qc45/image/upload/v1708784141/Volunteers/assets/175_Icono_Volunteers_nio4cr.png"
                  alt="Logo Dasos"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="presentation-article-2">
        <div className="forum-video-container">
          <video
            src="https://res.cloudinary.com/dd8a6qc45/video/upload/v1723330672/Volunteers/assets/videos/forum-bkgn-1920p_cx6bgi.mp4"
            autoPlay
            loop
            muted
            preload="auto"
          />
        </div>
        <div className="forum-logo-container">
          <img
            src="https://res.cloudinary.com/dd8a6qc45/image/upload/v1710177344/Volunteers/assets/TRIANGULO-NEGRO-VOLUNTEERS-1080p_qllgq2.png"
            alt="Logo Forum"
          />
        </div>
      </div>
      <div className="presentation-article-3">
        <div className="forum-video-container">
          <video
            src="https://res.cloudinary.com/dd8a6qc45/video/upload/v1723334937/Volunteers/assets/videos/forum-ods_2_gjhlza.mp4"
            autoPlay
            loop
            muted
            preload="auto"
          />
        </div>
        <div className="volunteers-logo-container">
          <div className="volunteers-logo-relative">
            <img
              className="volunteers-logo-background"
              src="https://cdn.pixabay.com/photo/2024/06/25/13/01/plant-8852648_1280.png"
              alt="Fondo plantas"
            />
            <div className="sub-text-volunteers-container">
              <div className="volunteers-img-container">
                <img
                  src="https://res.cloudinary.com/dd8a6qc45/image/upload/v1710177344/Volunteers/assets/TRIANGULO-NEGRO-VOLUNTEERS-1080p_qllgq2.png"
                  alt="Logo Forum"
                />
              </div>

              <p>
                El Foro internacional del Voluntariado en el Desarrollo es la{" "}
                <span>red mundial mas importante</span> de organizaciones que
                trabajan con <span>voluntarios</span> para alcanzar los{" "}
                <span>ODS</span> (Objetivos de Desarrollo Sostenible) de la{" "}
                <span>ONU</span>.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="presentation-article-4">
        <div className="img-container-article-4">
          <img
            src="https://res.cloudinary.com/dd8a6qc45/image/upload/v1723340469/Volunteers/assets/203916_ppjoqy.png"
            alt="Foto"
          />
        </div>
        <div className="sub-text-article-4">
          <div className="text-article-4-width">
            <div className="text-article-4">
              <p>
                Somos la <span>primera</span> organizacion de{" "}
                <span>latinoamerica</span> en esta red mundial de{" "}
                <span>voluntarios</span> que actuan por implementar los{" "}
                <span>ODS</span>
              </p>
              <div className="img-text-article-4">
                <img
                  src="https://res.cloudinary.com/dd8a6qc45/image/upload/v1708784141/Volunteers/assets/175_Icono_Volunteers_nio4cr.png"
                  alt="Logo Forum"
                />
              </div>
            </div>
          </div>

          <div className="numbers-text-article-4">
            <div className="numbers">
              <h2>30</h2>
              <p>Members</p>
            </div>
            <div className="numbers">
              <h2>83+</h2>
              <p>Organisations</p>
            </div>
            <div className="numbers">
              <h2>10,000+</h2>
              <p>Volunteers</p>
            </div>
            <div className="numbers">
              <h2>124</h2>
              <p>Countries</p>
            </div>
          </div>
          <div className="icons-text-article-4">
            <div className="img-icons-article-4">
              <img
                src="https://res.cloudinary.com/dd8a6qc45/image/upload/v1708784141/Volunteers/assets/175_Icono_Volunteers_nio4cr.png"
                alt="Logos compañias"
              />
            </div>
            <div className="img-icons-article-4">
              <img
                src="https://res.cloudinary.com/dd8a6qc45/image/upload/v1708784141/Volunteers/assets/175_Icono_Volunteers_nio4cr.png"
                alt="Logos compañias"
              />
            </div>
            <div className="img-icons-article-4">
              <img
                src="https://res.cloudinary.com/dd8a6qc45/image/upload/v1708784141/Volunteers/assets/175_Icono_Volunteers_nio4cr.png"
                alt="Logos compañias"
              />
            </div>
            <div className="img-icons-article-4">
              <img
                src="https://res.cloudinary.com/dd8a6qc45/image/upload/v1708784141/Volunteers/assets/175_Icono_Volunteers_nio4cr.png"
                alt="Logos compañias"
              />
            </div>
          </div>
          <div className="background-sub-text-article-4">
            <img
              src="https://images.pexels.com/photos/1572036/pexels-photo-1572036.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
              alt="Fondo hojas"
            />
          </div>
        </div>
      </div>
      <div className="presentation-article-5">
        <div className="text-article-5">
          <div className="p-text-article-5">
            <p>
              - Dos entrenamientos certificados para{" "}
              <span>Volunteers & Coach Ambiental</span>
            </p>
          </div>
          <div className="p-text-article-5">
            <p>
              - <span>18 actividades</span> para Volunteers y{" "}
              <span>27 actividades</span> para Coach Ambiental
            </p>
          </div>
          <div className="p-text-article-5">
            <p>
              - Todas las actividades siguen los lineamientos de los{" "}
              <span>O.D.S.</span> objetivos de desarrollo sostenible de la{" "}
              <span>ONU</span>
            </p>
          </div>
          <div className="p-text-article-5">
            <p>
              - Cada Volunteers obtiene su <span>uniforme</span> al iniciar el
              entrenamiento
            </p>
          </div>
          <div className="p-text-article-5">
            <p>
              - Disponemos de un grupo de expertos para guiar los Volunteers en
              el <span>calendario ambiental</span>
            </p>
          </div>
        </div>
        <div className="footer-article-5">
          <div className="footer-content-article-5">
            <div className="text-footer-article-5">
              <h2>QUE ES EL SISTEMA HOLISTICO DE ACTIVISMO AMBIENTAL?</h2>
            </div>
            <div className="img-container-footer-article-5">
              <img
                src="https://res.cloudinary.com/dd8a6qc45/image/upload/v1723347828/Volunteers/assets/223047_preview_rev_2_h1iqsb.png"
                alt="Foto ambiental"
              />
            </div>
            <div className="img-background-container-footer-article-5">
              <img
                src="https://images.pexels.com/photos/1572036/pexels-photo-1572036.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                alt="Fondo footer"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="presentation-article-5">
        <div className="text-article-5">
          <div className="p-text-article-5">
            <p>
              - <span>Carta de Invitacion</span> Internacional con QR para
              ingresar a la plataforma
            </p>
          </div>
          <div className="p-text-article-5">
            <p>
              - <span>Gorra bordada</span> alto relieve marquilla original
            </p>
          </div>
          <div className="p-text-article-5">
            <p>
              - <span>Botilito</span> Oficial Volunteers 1 Litro
            </p>
          </div>
          <div className="p-text-article-5">
            <p>
              - Cada Volunteers obtiene su <span>uniforme</span> al iniciar el
              entrenamiento
            </p>
          </div>
          <div className="p-text-article-5">
            <p>
              - <span>Agenda + Boligrafo</span> Ecologico
            </p>
          </div>
          <div className="p-text-article-5">
            <p>
              - <span>Maleta</span> cintura rodilla
            </p>
          </div>
        </div>
        <div className="img-iconos-material-apoyo">
          <div className="img-container-iconos-materia-apoyo">
            <img
              src="https://res.cloudinary.com/dd8a6qc45/image/upload/v1723399511/Volunteers/assets/125218_1_svgwgo.png"
              alt="Material"
            />
          </div>
          <div className="img-container-iconos-materia-apoyo">
            <img
              src="https://res.cloudinary.com/dd8a6qc45/image/upload/v1723399511/Volunteers/assets/125227_1_trppws.png"
              alt="Material"
            />
          </div>
          <div className="img-container-iconos-materia-apoyo">
            <img
              src="https://res.cloudinary.com/dd8a6qc45/image/upload/v1723399511/Volunteers/assets/125231_1_rjchsj.png"
              alt="Material"
            />
          </div>
          <div className="img-container-iconos-materia-apoyo">
            <img
              src="https://res.cloudinary.com/dd8a6qc45/image/upload/v1723399511/Volunteers/assets/125236_preview_rev_1_rn3vd2.png"
              alt="Material"
            />
          </div>
        </div>
        <div className="footer-article-5">
          <div className="footer-content-article-5">
            <div className="text-footer-article-5">
              <h2>MATERIAL DE APOYO ASPIRANTES</h2>
            </div>
            <div className="img-container-footer-article-5">
              <img
                src="https://res.cloudinary.com/dd8a6qc45/image/upload/v1723399511/Volunteers/assets/125132_preview_rev_1_lfxo4m.png"
                alt="Foto ambiental"
              />
            </div>
            <div className="img-background-container-footer-article-5">
              <img
                src="https://images.pexels.com/photos/1572036/pexels-photo-1572036.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                alt="Fondo footer"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Presentation;
