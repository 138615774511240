import React from "react";
import "../../styles/dashboard.css";

const Galery = () => {
  return (
    <div className="galery-container">
      <p>Galeria de fotos</p>
    </div>
  );
};

export default Galery;
