import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { toast } from "react-hot-toast";

import { useUser } from "../../context/providers/userContext";
import "../../styles/signup.css";
import "../../styles/resetPassword.css";
import { Spinner } from "../../components/ui/Spinner";
import Conditions from "../../components/ui/Conditions";

const ResetPassword = () => {
  const navigate = useNavigate();

  const [user, setUser] = useState({
    password: "",
    confirmPassword: "",
  });

  const {
    isLoading,
    errorMessage,
    resetPassword,
    passwordReset,
    errorMessagePassword,
    updatePassword,
  } = useUser();

  const verifyToken = async () => {
    try {
      const params = new URLSearchParams(window.location.search);
      const token = params.get("token");
      await resetPassword(token);
    } catch (error) {
      console.log(errorMessagePassword);
    }
  };

  const handleChange = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const { confirmPassword, ...userData } = user;
      const userResponse = await updatePassword(passwordReset._id, userData);
      if (userResponse) {
        toast.success(
          `Hola ${passwordReset.name}, Has restablecido tú contraseña.`,
          {
            position: "bottom-right",
          }
        );
        navigate("/");
      }
    } catch (error) {
      console.log(errorMessage);
      toast.error(`${error}`, {
        position: "bottom-right",
      });
    }
  };

  

  useEffect(() => {
    verifyToken();
  }, []);

  return (
    <main className="main">
      {isLoading ? (
        Spinner
      ) : (
        <>
          {passwordReset ? (
            <section className="section-signup">
              <div className="user-password-container">
                <div className="user-password-img-container">
                  <img
                    src={
                      passwordReset?.image?.url ||
                      "https://res.cloudinary.com/dd8a6qc45/image/upload/v1690634531/Volunteers/perfilImage/account_e6qpmh.svg"
                    }
                    alt=""
                  />
                </div>
                <h2 className="title-send-email">
                  Hola de nuevo {passwordReset.name}
                </h2>
              </div>

              <div className="form">
                <Formik
                  initialValues={user}
                  validationSchema={Yup.object({
                    password: Yup.string()
                      .required("*")
                      .min(6, "La contraseña debe tener minio 6 caracteres."),
                    confirmPassword: Yup.string().oneOf(
                      [Yup.ref("password"), null],
                      "Las contraseñas deben coincidir. "
                    ),
                  })}
                  onSubmit={handleSubmit}
                  enableReinitialize={true}
                  className="form"
                >
                  <Form onSubmit={handleSubmit}>
                    <ErrorMessage
                      className="error-message"
                      component="p"
                      name="password"
                    />
                    <div className="input-label-signup">
                      <div className="img-icon">
                        <img
                          className="icono"
                          src="https://i.postimg.cc/2SwnVxNt/CONTRASE-A.png"
                          alt=""
                        />
                        <img src="" alt="" />
                      </div>
                      <Field
                        className="input-signup"
                        name="password"
                        type="password"
                        required
                        autoComplete="current-password"
                        onChange={handleChange}
                      />
                      <label className="label-signup" htmlFor="password">
                        Contraseña
                      </label>
                    </div>
                    <ErrorMessage
                      className="error-message"
                      component="p"
                      name="confirmPassword"
                    />
                    <div className="input-label-signup">
                      <div className="img-icon">
                        <img
                          className="icono"
                          src="https://i.postimg.cc/2SwnVxNt/CONTRASE-A.png"
                          alt=""
                        />
                        <img src="" alt="" />
                      </div>
                      <Field
                        className="input-signup"
                        name="confirmPassword"
                        type="password"
                        required
                        autoComplete="current-password"
                        onChange={handleChange}
                      />
                      <label className="label-signup" htmlFor="password">
                        Confirmar Contraseña
                      </label>
                    </div>

                    <button
                      className="button-enter-signup"
                      type="submit"
                      disabled={!user.password || isLoading}
                    >
                      {isLoading ? "Cargando..." : "RESTABLECER CONTRASEÑA"}
                    </button>
                    <Link to="/conditions" className="terminosycondiciones">
                      Terminos & Condiciones
                    </Link>
                  </Form>
                </Formik>
              </div>
            </section>
          ) : (
            <>
              <h2>Usuario no encontrado</h2>
            </>
          )}
        </>
      )}
    </main>
  );
};

export default ResetPassword;
