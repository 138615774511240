import React from "react";

const OdsActivity15 = () => {
  return (
    <div className="ods-activities-container">
      <h2>ODS ACTIVIDAD 15:</h2>
      <div className="ods-activity-card">
        <div className="ods-activity-image">
          <img
            src="https://res.cloudinary.com/dd8a6qc45/image/upload/v1722645231/Volunteers/assets/ODS/3_tqqywb.png"
            alt="Numero ODS"
          />
        </div>
        <p>
          <span>ODS 3: Salud y Bienestar: (Meta 3.9) </span> <br />
          Un uso más eficiente del agua puede asegurar que haya suficiente agua
          limpia disponible para beber y para el saneamiento, lo cual es
          esencial para la salud y el bienestar de la población.
        </p>
      </div>
      <div className="ods-activity-card">
        <div className="ods-activity-image">
          <img
            src="https://res.cloudinary.com/dd8a6qc45/image/upload/v1722645230/Volunteers/assets/ODS/6_mwuiqs.png"
            alt="Numero ODS"
          />
        </div>
        <p>
          <span>
            ODS 6: Agua Limpia y Saneamiento: (Meta 6.1, 6.3, 6.4, 6.5){" "}
          </span>{" "}
          <br />
          La reducción del consumo de agua en el hogar contribuye directamente a
          la gestión sostenible del agua, asegurando su disponibilidad y
          promoviendo el uso eficiente de este recurso vital.
        </p>
      </div>
      <div className="ods-activity-card">
        <div className="ods-activity-image">
          <img
            src="https://res.cloudinary.com/dd8a6qc45/image/upload/v1722645230/Volunteers/assets/ODS/7_gguoxk.png"
            alt="Numero ODS"
          />
        </div>
        <p>
          <span>ODS 7: Energía Asequible y No Contaminante: (Meta 7.3) </span>{" "}
          <br />
          La reducción del consumo de agua puede disminuir la demanda de energía
          necesaria para el bombeo, tratamiento y distribución del agua,
          promoviendo el uso eficiente de la energía y reduciendo el impacto
          ambiental.
        </p>
      </div>
      <div className="ods-activity-card">
        <div className="ods-activity-image">
          <img
            src="https://res.cloudinary.com/dd8a6qc45/image/upload/v1722645227/Volunteers/assets/ODS/9_gwz6lw.png"
            alt="Numero ODS"
          />
        </div>
        <p>
          <span>
            ODS 9: Industria, Innovación e Infraestructura: (Meta 9.4){" "}
          </span>{" "}
          <br />
          La implementación de tecnologías y prácticas innovadoras para reducir
          el consumo de agua en el hogar puede fomentar el desarrollo de
          infraestructuras más sostenibles y eficientes.
        </p>
      </div>
      <div className="ods-activity-card">
        <div className="ods-activity-image">
          <img
            src="https://res.cloudinary.com/dd8a6qc45/image/upload/v1722645225/Volunteers/assets/ODS/11_qyelnc.png"
            alt="Numero ODS"
          />
        </div>
        <p>
          <span>ODS 11: Ciudades y Comunidades Sostenibles: (Meta 11.6) </span>{" "}
          <br />
          Un menor consumo de agua en los hogares contribuye a la sostenibilidad
          urbana, ayudando a las ciudades a gestionar sus recursos hídricos de
          manera más eficaz y resiliente.
        </p>
      </div>
      <div className="ods-activity-card">
        <div className="ods-activity-image">
          <img
            src="https://res.cloudinary.com/dd8a6qc45/image/upload/v1722645225/Volunteers/assets/ODS/12_totmyp.png"
            alt="Numero ODS"
          />
        </div>
        <p>
          <span>
            ODS 12: Producción y Consumo Responsables: (Meta 12.2, 12.5){" "}
          </span>{" "}
          <br />
          Disminuir el consumo de agua promueve prácticas de consumo
          responsables.
        </p>
      </div>
      <div className="ods-activity-card">
        <div className="ods-activity-image">
          <img
            src="https://res.cloudinary.com/dd8a6qc45/image/upload/v1722645225/Volunteers/assets/ODS/13_tmtnkh.png"
            alt="Numero ODS"
          />
        </div>
        <p>
          <span>ODS 13: Acción por el Clima: (Meta 13.1) </span> <br />
          Reducir el consumo de agua en el hogar puede contribuir a la
          mitigación del cambio climático, ya que se disminuye la energía
          utilizada en el tratamiento y distribución del agua, reduciendo así
          las emisiones de gases de efecto invernadero.
        </p>
      </div>
      <div className="ods-activity-card">
        <div className="ods-activity-image">
          <img
            src="https://res.cloudinary.com/dd8a6qc45/image/upload/v1722645225/Volunteers/assets/ODS/14_mbd8kd.png"
            alt="Numero ODS"
          />
        </div>
        <p>
          <span>ODS 14: Vida Submarina: (Meta 14.1) </span> <br />
          Disminuir el consumo de agua puede reducir la cantidad de aguas
          residuales que llegan a los cuerpos de agua, ayudando a proteger los
          ecosistemas marinos y la vida submarina de la contaminación.
        </p>
      </div>
      <div className="ods-activity-card">
        <div className="ods-activity-image">
          <img
            src="https://res.cloudinary.com/dd8a6qc45/image/upload/v1722645225/Volunteers/assets/ODS/15_hd4qmx.png"
            alt="Numero ODS"
          />
        </div>
        <p>
          <span>ODS 15: Vida de Ecosistemas Terrestres: (Meta 15.1) </span>{" "}
          <br />
          Un uso más eficiente del agua ayuda a proteger los ecosistemas
          terrestres y su biodiversidad, ya que menos extracción de agua reduce
          el estrés en los ríos, lagos y acuíferos.
        </p>
      </div>
      <div className="ods-activity-card">
        <div className="ods-activity-image">
          <img
            src="https://res.cloudinary.com/dd8a6qc45/image/upload/v1722645225/Volunteers/assets/ODS/17_e8x3cm.png"
            alt="Numero ODS"
          />
        </div>
        <p>
          <span>ODS 17: Alianzas para lograr los objetivos: (Meta 17.16) </span>{" "}
          <br />
          Fomentar la reducción del consumo de agua en el hogar puede involucrar
          a diversas partes interesadas, incluyendo gobiernos, comunidades y
          organizaciones no gubernamentales, promoviendo colaboraciones y
          alianzas para alcanzar los ODS.
        </p>
      </div>
    </div>
  );
};

export default OdsActivity15;
