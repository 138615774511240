import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import { faEye, faTrashCan } from "@fortawesome/free-regular-svg-icons";
import { Link } from "react-router-dom";
import { Spinner } from "../Spinner";
import { useUser } from "../../../context/providers/userContext";
import "../../../styles/adminPanel.css";
import { toast } from "react-hot-toast";
import Swal from "sweetalert2";

const AdminUsers = ({ users }) => {
  const { isLoading, getAllUsers, deleteUserAndTaksAdmin } = useUser();

  const handleDelete = async (e, idUser) => {
    e.preventDefault();
    try {
      Swal.fire({
        title:
          "Estas seguro de borrar la cuenta del usuario? No la podras volver a recuperar.",
        showDenyButton: true,
        // showCancelButton: true,
        confirmButtonText: "Eliminar",
        denyButtonText: `Cancelar`,
      }).then(async (result) => {
        if (result.isConfirmed) {
          const userDelete = await deleteUserAndTaksAdmin(idUser);
          await getAllUsers();
          console.log(userDelete);

          // toast.success("Usuario eliminado con exito!", {
          //   position: "bottom-right",
          // });
          Swal.fire("Cuenta eliminada!", "", "success");
        } else if (result.isDenied) {
          Swal.fire("Cuenta salvada!", "", "info");
        }
      });
    } catch (error) {
      toast.error("El usuario no ha sido eliminado!", {
        position: "bottom-right",
      });
      console.log(error);
    }
  };

  if (isLoading) {
    return Spinner;
  }
  return (
    <div className="article admin-page-users-container">
      <div className="title-card-admin">
        <h2>USUARIOS</h2>
        <Link to="/activities-admin">Actividades</Link>
        <Link to="/codes-admin">Codigos</Link>
      </div>
      <div className="content-admin-card">
        <div className="table">
          <table>
            <thead>
              <tr>
                <th>Check</th>
                <th>Fecha</th>
                <th>Nombre</th>
                <th>Documento</th>
                <th>Correo</th>
                <th>Rol</th>
                <th>Ciudad</th>
                <th>Telefono</th>
                <th>Perfil</th>
                <th>Accion</th>
              </tr>
            </thead>
            <tbody>
              {users?.map((user) => (
                <tr key={user._id}>
                  {user.complete === 0 ? (
                    <td className="check-icon check-null">
                      <FontAwesomeIcon icon={faCircleCheck} />
                    </td>
                  ) : user.complete === 1 ? (
                    <td className="check-icon check-review">
                      <FontAwesomeIcon icon={faCircleCheck} />
                    </td>
                  ) : user.complete === 2 ? (
                    <td className="check-icon check-return">
                      <FontAwesomeIcon icon={faCircleCheck} />
                    </td>
                  ) : user.complete === 3 ? (
                    <td className="check-icon check-complete">
                      <FontAwesomeIcon icon={faCircleCheck} />
                    </td>
                  ) : (
                    <></>
                  )}
                  <td>
                    {user.createdAt.substring(0, user.createdAt.indexOf("T"))}
                  </td>
                  <td>{user.name}</td>
                  <td>{user.document}</td>
                  <td>{user.email}</td>
                  <td>
                    {user.roles.map((rol) => (
                      <div key={rol._id}>
                        <p>{rol.name}</p>
                      </div>
                    ))}
                  </td>
                  <td>{user.country}</td>
                  <td>{user.number}</td>
                  <td className="img-card-admin">
                    <img
                      src={
                        user.image?.url ||
                        "https://res.cloudinary.com/dd8a6qc45/image/upload/v1690638814/Volunteers/perfilImage/fuonicgkazr1m9bntyet.svg"
                      }
                      alt="Imagen de perfil"
                    />
                  </td>
                  <td className="action-card-admin">
                    <div className="button-panel-admin-container">
                      <button
                        className="button-panel-admin-activity"
                        onClick={(e) => {
                          handleDelete(e, user._id);
                        }}
                      >
                        <FontAwesomeIcon icon={faTrashCan} />
                      </button>
                      <Link to={`account/${user._id}`}>
                        <FontAwesomeIcon icon={faEye} />
                      </Link>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default AdminUsers;
