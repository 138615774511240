import React from "react";
import "../../styles/conditions.css";

const Conditions = () => {
  return (
    <section className="section-conditions">
      <h2>TERMINOS Y CONDICIONES</h2>
      <div className="condition-content">
        <div className="numero-termino">
          <p className="condition-p">
            <span>1. Aceptación de los Términos</span>
            <br />
            Al acceder y utilizar nuestro servicio de IA, usted acepta estar
            sujeto a estos términos y condiciones. Si no está de acuerdo con
            alguna parte de estos términos, no utilice el servicio.
          </p>
          <p className="condition-p">
            <span>2. Descripción del Servicio</span>
            <br />
            Nuestro servicio de IA se dedica a identificar, planificar y
            ejecutar actividades que contribuyan a la protección y mejora del
            medio ambiente. Estas actividades pueden incluir, pero no se limitan
            a:
            <br />
            <span>•</span> Reducción de emisiones de carbono
            <br />
            <span>•</span> Gestión de residuos
            <br />
            <span>•</span> Conservación de recursos naturales
            <br />
            <span>•</span> Educación y concienciación ambiental
          </p>
          <p className="condition-p">
            <span>3. Uso del Servicio</span>
            <br />
            Uso Permitido: El servicio está destinado a usuarios que desean
            contribuir activamente a la protección del medio ambiente a través
            de iniciativas respaldadas por IA. <br />
            Registro: Algunos servicios pueden requerir la creación de una
            cuenta. Usted es responsable de mantener la confidencialidad de su
            información de cuenta y de todas las actividades que ocurran bajo su
            cuenta. <br />
            Conducta del Usuario: Usted se compromete a utilizar el servicio de
            manera ética y legal, evitando cualquier acción que pueda dañar el
            medio ambiente, nuestra IA, otros usuarios o terceros.
          </p>
          <p className="condition-p">
            <span>4. Propiedad</span>
            <br />
            Esta página web es propiedad y está operada por la FUNDACIÓN DASOS
            identificada con NIT 901614214-3. Estos Términos establecen los
            términos y condiciones bajo los cuales tú puedes usar nuestra página
            web y servicios ofrecidos por nosotros. Esta página web ofrece a los
            visitantes la oportunidad de realizar las 27 actividades con el
            objeto de realizar un voluntariado desde cualquier lugar del mundo
            contra del cambio climático, la contaminación y los malos hábitos de
            consumo. Al acceder o usar la página web de nuestro servicio, tu
            apruebas, lees, entiendes y aceptas estar sujeto a estos Términos:
          </p>
          <p className="condition-p">
            <span>5. Uso</span>
            <br />
            Para usar nuestra página web y / o recibir nuestros servicios, debes
            tener al menos 5 años de edad, y si eres menor de edad en tu país,
            debes estar supervisado por tus padres o un mayor de edad
            responsable, de igual forma debes poseer la autoridad legal, el
            derecho y la libertad para participar en estos términos como un
            acuerdo vinculante. No tienes permitido utilizar esta página web y /
            o recibir servicios si hacerlo está prohibido en tu país o en virtud
            de cualquier ley o regulación aplicable a tu caso. <br />
            Para cualquier producto ofrecido ya sea vendido y/o obsequiado que
            llegare a su destino dañado, simplemente devuélvelo con los
            accesorios y el paquete incluidos junto con el recibo original (o
            recibo de obsequio) dentro de los 15 días calendario posteriores a
            la fecha que recibiste el producto, y lo cambiaremos o te
            ofreceremos un reembolso basado en el método de pago original.
            Además, ten en cuenta lo siguiente: Los productos solo se pueden
            devolver en el país en el que se adquirieron originalmente. <br />
            La propietaria de la página web puede, sin aviso previo, cambiar los
            servicios; dejar de proporcionar los servicios o cualquier
            característica de los servicios que ofrecemos; o crear límites para
            los servicios. De igual forma puede suspender de manera permanente o
            temporal el acceso a los servicios, sin previo aviso, ni
            responsabilidad por cualquier motivo, o sin ningún motivo.
          </p>
          <p className="condition-p">
            <span>6. Propiedad Intelectual</span>
            <br />
            El servicio y todos los materiales incluidos o transferidos,
            incluyendo, sin limitación, software, imágenes, texto, gráficos,
            logotipos, patentes, marcas registradas, marcas de servicio,
            derechos de autor, fotografías, audio, videos, música y todos los
            Derechos de Propiedad Intelectual relacionados con ellos, son la
            propiedad exclusiva de la Fundación DASOS. Salvo que se indique
            explícitamente en este documento, no se considerará que nada en
            estos términos crea una licencia en o bajo ninguno de dichos
            derechos de propiedad intelectual, y tu aceptas no vender,
            licenciar, alquilar, modificar, distribuir, copiar, reproducir,
            transmitir, exhibir públicamente, realizar públicamente, publicar,
            adaptar, editar o crear trabajos derivados de los mismos. <br />
            Podemos terminar o suspender de manera permanente o temporal tu
            acceso al servicio sin previo aviso y responsabilidad por cualquier
            razón, incluso si a nuestra sola determinación tu violas alguna
            disposición de estos términos o cualquier ley o regulación
            aplicable. Puedes descontinuar el uso y solicitar cancelar tu cuenta
            y / o cualquier servicio en cualquier momento. Sin perjuicio de lo
            contrario en lo que antecede, con respecto a las suscripciones
            renovadas automáticamente a los servicios pagados, dichas
            suscripciones se suspenderán solo al vencimiento del período
            correspondiente por el que ya has realizado el pago.
          </p>
          <p className="condition-p">
            <span>
              <br />
            </span>
            <br />
            Al publicar contenido en página web garantizas que eres el
            propietario del contenido y que tienes todos los derechos necesarios
            para publicarlo. También aceptas que tu contenido se publicará bajo
            una licencia no exclusiva, libre de regalías, transferible y
            sublicenciable.
          </p>
          <p className="condition-p">
            <span>8. Conducta del Usuario </span>
            <br />
            Te comprometes a utilizar nuestra página web de red social ambiental
            de manera responsable y ética, y a no publicar ningún contenido
            inapropiado, ofensivo o ilegal. Nos reservamos el derecho de
            eliminar cualquier contenido o usuario que consideremos inapropiado.
          </p>
          <p className="condition-p">
            <span>9. Indemnidad </span>
            <br />
            Tu acuerdas indemnizar y eximir de responsabilidad a la Fundación
            DASOS de cualquier demanda, pérdida, responsabilidad, reclamación o
            gasto (incluidos los honorarios de abogados) que terceros realicen
            en tu contra como consecuencia de, o derivado de, o en relación con
            tu uso de la página web o cualquiera de los servicios ofrecidos en
            la página web. <br />
            En la máxima medida permitida por la ley aplicable, en ningún caso
            la Fundación DASOS será responsable por daños accidentales,
            directos, indirectos, punitivos, incidentales, especiales,
            consecuentes o ejemplares, incluidos, entre otros, daños por pérdida
            de beneficios, físicos, emocionales, uso, datos u otras pérdidas
            tangibles e intangibles, que surjan de, o estén relacionadas con el
            uso o la imposibilidad de utilizar el servicio. <br />
            En la máxima medida permitida por la ley aplicable, Fundación DASOS
            no asume responsabilidad alguna por (i) errores o inexactitudes de
            contenido; (ii) lesiones personales o daños a la propiedad, de
            cualquier naturaleza que sean, como resultado de tu acceso y/o uso
            de nuestro servicio y/o página web; y (iii) cualquier acceso no
            autorizado o uso de nuestros servidores seguros y/o toda la
            información personal almacenada en los mismos.
          </p>
          <p className="condition-p">
            <span>10. Modificación</span>
            <br />
            Nos reservamos el derecho de modificar estos términos de vez en
            cuando a nuestra entera discreción. Por lo tanto, debes revisar
            estos términos y condiciones periódicamente. Cuando cambiemos los
            términos de una manera material, te notificaremos que se han
            realizado cambios importantes en los términos. El uso continuado de
            la página web o nuestro servicio después de dicho cambio constituye
            tu aceptación de los nuevos términos. Si no aceptas alguno de estos
            términos o cualquier versión futura de los términos, no uses o
            accedas (o continúes accediendo) a la página web o al servicio.
          </p>
          <p className="condition-p">
            <span>11. Mensajería</span>
            <br />
            Aceptas recibir de vez en cuando nuestros mensajes y materiales de
            promoción, por correo postal, correo electrónico o cualquier otro
            formulario de contacto que nos proporciones (incluido tu número de
            teléfono para llamadas o mensajes de texto). Si no deseas recibir
            dichos materiales o avisos de promociones, simplemente avísanos en
            cualquier momento, al correo electrónico evolunteers.org@gmail.com.{" "}
            <br />
            Estos términos, los derechos y recursos provistos aquí, y todos y
            cada uno de los reclamos y disputas relacionados con este y/o los
            servicios, se regirán, interpretarán y aplicarán en todos los
            aspectos única y exclusivamente de conformidad con las leyes
            sustantivas internas de Colombia, con respeto a sus principios de
            conflicto de leyes. Todos los reclamos y disputas se presentarán y
            aceptas que sean decididos exclusivamente por un tribunal de
            jurisdicción competente ubicada en Bogotá D.C.-Colombia. La
            aplicación de la Convención de Contratos de las Naciones Unidas para
            la Venta Internacional de Bienes queda expresamente excluida.
          </p>
          <p className="condition-p">
            <span>12. Tratamiento de Datos</span>
            <br />
            En cumplimiento de la Ley Estatutaria 1581 de 2012, “Por la cual se
            dictan disposiciones generales para la protección de datos
            personales”, (en adelante LEPD) y sus Decretos reglamentarios 1377
            de 2103, “Por el cual se reglamenta parcialmente la Ley 1581 de
            2012”, y el Decreto 1074 de 2015" Por medio del cual se expide el
            Decreto Único Reglamentario del Sector Comercio, Industria y
            Turismo, y demás normas concordantes, te informamos como titular de
            datos que, por medio de tu conducta inequívoca estás otorgando de
            manera razonable la autorización para el tratamiento de tus datos
            personales que divulgues en la presente página web, de manera
            inequívoca, voluntaria, sin vicios de consentimiento como dolo,
            error o intimidación los cuales será incorporados en una base de
            datos responsabilidad de la Fundación DASOS. <br />
            <span>a)</span> Tratamiento y finalidad: Tus datos personales serán
            utilizados con el fin de especificar la finalidad del tratamiento,
            por ejemplo: gestionar tu participación en nuestra plataforma de
            redes sociales ambientales, permitirte compartir contenido,
            interactuar con otros usuarios y promover la comunidad de usuarios
            comprometidos con el cuidado del medio ambiente. <br />
            <span>b)</span> Carácter facultativo: La respuesta a las preguntas
            que te hagamos en relación con datos sensibles o datos de niñas,
            niños y adolescentes es completamente voluntaria. En caso de que
            decidas no responder, no se afectará tu acceso y participación en
            nuestra plataforma. <br />
            <span>c)</span> Derechos del Titular: Como titular de los datos
            personales, tienes derecho a acceder, conocer, actualizar,
            rectificar y suprimir tu información personal, así como a solicitar
            la revocación de la autorización otorgada para el tratamiento de tus
            datos, de conformidad con lo establecido en la legislación vigente.{" "}
            <br />
            Puedes ejercer tus derechos de acceso, actualización, corrección,
            supresión, revocación o reclamo por infracción sobre sus datos,
            mediante escrito dirigido a la Fundación DASOS, a dirección de
            correo electrónico evolunteers.org@gmail.com, indicando en el asunto
            el derecho que desea ejercer. <br />
            Tu cuenta será pública y, por lo tanto, visible para los visitantes
            de la página, de igual forma puedes optar por no participar y salir
            de la comunidad, y al hacerlo, tu cuenta no será visible
            públicamente. Naturalmente, en tal caso, por medio de tu cuenta no
            podrá usar las funciones de la comunidad (por ejemplo, me gusta,
            comentar, subir contenido visual o audiovisual o escribir
            publicaciones). <br />
          </p>
          <p className="condition-p">
            <span>13. Privacidad </span>
            <br />
            Respetamos tu privacidad y nos comprometemos a proteger tus datos
            personales. Consulta nuestra política de privacidad para obtener más
            información sobre cómo recopilamos, utilizamos y protegemos tus
            datos personales. El Titular de datos declara haber leído la
            cláusula anterior y estar conforme con la misma.
          </p>
          <p className="condition-p">
            <span>14. Aviso de Privacidad</span>
            <br />
            En cumplimiento de lo dispuesto en el Decreto 1377 de 2013 y demás
            normativas aplicables, se emite el presente Aviso de Privacidad para
            informar a los usuarios de la página web denominada
            www.evolunteers.org sobre las políticas de tratamiento de datos
            personales que serán aplicables al utilizar nuestros servicios.{" "}
            <br />
            <span>14.1</span> Responsable del Tratamiento de Datos Personales El
            responsable del tratamiento de los datos personales recopilados a
            través de esta página web es la Fundación DASOS, con domicilio en
            Colombia y número de identificación NIT 901214614. <br />
            <span>14.2</span> Finalidades del Tratamiento Los datos personales
            proporcionados por los usuarios a través de la página web serán
            tratados con el propósito de gestionar y administrar los servicios
            ofrecidos en la plataforma. Estas finalidades incluyen, entre otras:{" "}
            <br />
            <span>•</span> Permitir a los usuarios subir fotos relacionadas con
            las actividades GUIA VOLUNTEERS y compartir contenido en la página
            web. <br />
            <span>•</span> Facilitar la interacción entre los usuarios y
            fomentar la participación en la comunidad. <br />
            <span>•</span> Gestionar y mantener la seguridad de la plataforma y
            prevenir fraudes o conductas ilícitas. <br />
            <span>14.3</span> Datos Personales Recopilados Los datos personales
            que se recopilan a través de la página web pueden incluir, entre
            otros: <br />
            <span>•</span> Nombre y apellidos. <br />
            <span>•</span> Dirección de correo electrónico. <br />
            <span>•</span> Información de contacto. <br />
            <span>•</span> Fotografías y contenido generado por los usuarios.{" "}
            <br />
            <span>14.4</span> Consentimiento y Derechos del Titular Al utilizar
            nuestra página web y proporcionar sus datos personales, usted
            manifiesta haber leído y entendido este Aviso de Privacidad,
            otorgando su consentimiento expreso para el tratamiento de sus datos
            personales de acuerdo con las finalidades descritas en el punto 2.
            Asimismo, se le informa que como titular de los datos tiene derecho
            a acceder, rectificar, actualizar y suprimir sus datos personales,
            así como a ejercer cualquier otro derecho reconocido por la
            normativa vigente. <br />
            <span>14.5</span> Seguridad de los datos personales nos
            comprometemos a tratar los datos personales de los usuarios con la
            debida confidencialidad y adoptar las medidas de seguridad
            necesarias para evitar su alteración, pérdida, acceso no autorizado
            o cualquier otro tratamiento no autorizado. <br />
            <span>14.6</span> Transferencia de datos personales los datos
            personales recopilados a través de la página web podrán ser
            compartidos con terceros proveedores de servicios que colaboran en
            la operación y mantenimiento de la plataforma, siempre y cuando se
            garantice la debida protección de los datos personales y se cumplan
            los requisitos legales aplicables. <br />
            <span>14.7</span> Cambios en el aviso de privacidad nos reservamos
            el derecho de modificar o actualizar este aviso de privacidad en
            cualquier momento. Cualquier cambio será debidamente informado a
            través de la página web. <br />
            Si tienes alguna pregunta, inquietud o deseas ejercer sus derechos
            como titular de los datos personales, puedes comunicarte con
            nosotros a través de evolunteers.org@gmail.com o mediante los
            canales de comunicación proporcionados en la página web.
          </p>
          <p className="condition-p">
            <span>15. Propiedad Intelectual</span>
            <br />
            Derechos de Autor: Todo el contenido y materiales proporcionados por
            el servicio de IA están protegidos por derechos de autor y otras
            leyes de propiedad intelectual. No puede copiar, modificar,
            distribuir o crear trabajos derivados sin el consentimiento expreso
            de los titulares de los derechos. <br />
            Licencia de Uso: Al utilizar nuestro servicio, le otorgamos una
            licencia limitada, no exclusiva y revocable para acceder y usar el
            servicio según estos términos.
          </p>
          <p className="condition-p">
            <span>16. Privacidad y Datos Personales</span>
            <br />
            Recopilación de Datos: Podemos recopilar y procesar datos personales
            y ambientales necesarios para el funcionamiento del servicio. Esto
            puede incluir información sobre actividades realizadas, ubicaciones
            geográficas y datos de impacto ambiental. <br />
            • Uso de Datos: Sus datos serán utilizados exclusivamente para
            mejorar el servicio y para fines de investigación y desarrollo en
            pro del medio ambiente. Nos comprometemos a no vender, alquilar o
            compartir sus datos con terceros sin su consentimiento explícito,
            salvo en cumplimiento de la ley. <br />• Seguridad de Datos:
            Implementamos medidas de seguridad adecuadas para proteger sus datos
            personales contra el acceso no autorizado, la alteración,
            divulgación o destrucción.
          </p>
          <p className="condition-p">
            <span>17. Limitación de Responsabilidad</span>
            <br />
            • Exactitud de la Información: Aunque nos esforzamos por
            proporcionar información precisa y actualizada, no garantizamos la
            exactitud, integridad o idoneidad de cualquier información
            proporcionada por la IA. <br />• Daños y Perjuicios: En la máxima
            medida permitida por la ley, no seremos responsables por ningún daño
            directo, indirecto, incidental, especial o consecuente que resulte
            del uso o la imposibilidad de uso del servicio.
          </p>
          <p className="condition-p">
            <span>18. Modificaciones de los Términos</span>
            <br />
            Nos reservamos el derecho de modificar estos términos y condiciones
            en cualquier momento. Las modificaciones entrarán en vigor
            inmediatamente después de su publicación. Es su responsabilidad
            revisar regularmente estos términos y condiciones para estar al
            tanto de cualquier cambio.
          </p>
          <p className="condition-p">
            <span>19. Terminación del Servicio</span>
            <br />
            Podemos suspender o terminar su acceso al servicio en cualquier
            momento, sin previo aviso, si consideramos que ha violado estos
            términos y condiciones o por cualquier otra razón que consideremos
            apropiada.
          </p>
          <p className="condition-p">
            <span>20. Ley Aplicable</span>
            <br />
            Estos términos y condiciones se regirán e interpretarán de acuerdo
            con las leyes aplicables en la República de Colombia. Cualquier
            disputa que surja en relación con estos términos se someterá
            exclusivamente a los tribunales competentes de la República de
            Colombia.
          </p>
          <p className="condition-p">
            <span>21. Contacto</span>
            <br />
            Si tiene alguna pregunta o comentario sobre estos términos y
            condiciones, puede contactarnos en [correo electrónico/contacto].
          </p>
          <p className="condition-p">
            <br />
            Fecha de última actualización: 03 de Junio de 2023 <br />
            Agradecemos tu confianza y el uso de nuestros servicios. <br />
            <br />
            <br />
            Atentamente,
            <br />
            Area legar VOLUNTEERS.
          </p>
        </div>
      </div>
    </section>
  );
};

export default Conditions;
