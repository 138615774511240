import React, { useEffect } from "react";
import { Link } from "react-router-dom";

import "../styles/account.css";
import { useUser } from "../context/providers/userContext";
import { Spinner } from "../components/ui/Spinner";
import { toast } from "react-hot-toast";
import AccountPerfil from "../components/ui/AccountPerfil";

const Account = () => {
  const { user, getUserTareas, userTareas, isLoading, updateUser } = useUser();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const us = user;
      us.complete = 1;
      const userResponse = await updateUser(user._id, us);
      if (userResponse) {
        toast.success("Tareas enviadas satisfactoriamente!", {
          position: "bottom-right",
        });
      }
    } catch (error) {
      toast.error("Tareas enviadas satisfactoriamente!", {
        position: "bottom-right",
      });
      console.log(error);
    }
  };

  useEffect(() => {
    getUserTareas(user._id);
  }, []);

  if (isLoading) {
    return Spinner;
  }

  return (
    <main className="main-account">
      <div>
        <AccountPerfil/>
        <section className="cards">
          <article className="activity">
            <div className="activity-head">ACTIVIDADES NIVEL 4</div>
            <div className="card-content">
              <div className="activity-text">
                <p className="text-activity">
                  <span className="number-activity">19.</span> Plantar mínimo un
                  árbol nativo, describiendo sus características, coordenadas y
                  fotografías del lugar.
                </p>
                <p className="text-activity">
                  <span className="number-activity">20.</span> Construir una
                  técnica para hidratar los árboles plantados, realice una
                  descripción del paso a paso y tome fotografías del
                  procedimiento.
                </p>
                <p className="text-activity">
                  <span className="number-activity">21.</span> Elaborar abonos
                  orgánicos como compostaje o humus para aplicar en los árboles
                  plantados y describir la técnica usada mediante fotografías.
                </p>
                <p className="text-activity">
                  <span className="number-activity">22.</span> Utilizar medio de
                  transporte alternativo durante una semana, subir imagen de
                  cada día, describiendo su experiencia en bicicleta, patines
                  entre otros.
                </p>
                <p className="text-activity">
                  <span className="number-activity">23.</span> Identifique una
                  norma legal ambiental, tome una fotografía y realice un ensayo
                  en la descripción.
                </p>
                <p className="text-activity">
                  <span className="number-activity">24.</span> Fruta comida,
                  semilla sembrada, construya un semillero con mínimo (3) frutas
                  diferentes, fotografié y describa las características de cada
                  una.
                </p>
                <p className="text-activity">
                  <span className="number-activity">25.</span> Identifique y
                  fotografié un impacto ambiental negativo en su hábitat y
                  formule una solución en la descripción.
                </p>
                <p className="text-activity">
                  <span className="number-activity">26.</span> Realizar actividad
                  física en grupo, fotografié y describa su experiencia junto
                  con el tipo de deporte utilizado, describa a sus acompañantes.
                </p>
                <p className="text-activity">
                  <span className="number-activity">27.</span> Organice un compa
                  sin usar desechables e invite a familiares y amigos, describa
                  las alternativas que utilizo y comparta fotografías con los
                  invitados.
                </p>
              </div>
              <div className="activity-images-n1">
                <div className="card-imgpng uppng-n1 image-n1">
                  <img
                    src={
                      userTareas[21] && userTareas[21].image
                        ? userTareas[21].image.url
                        : "https://res.cloudinary.com/dd8a6qc45/image/upload/v1708994985/Volunteers/assets/mundo_ka9mlm.png"
                    }
                    alt=""
                  />

                  <img
                    src={
                      userTareas[23] && userTareas[23].image
                        ? userTareas[23].image.url
                        : "https://res.cloudinary.com/dd8a6qc45/image/upload/v1708994985/Volunteers/assets/mundo_ka9mlm.png"
                    }
                    alt=""
                  />
                </div>
                <div className="card-imgpng centerpng-n1 centerpngimg-n1 image-n1">
                  <img
                    src={
                      userTareas[18] && userTareas[18].image
                        ? userTareas[18].image.url
                        : "https://res.cloudinary.com/dd8a6qc45/image/upload/v1708994985/Volunteers/assets/mundo_ka9mlm.png"
                    }
                    alt=""
                  />
                  <img
                    src={
                      userTareas[20] && userTareas[20].image
                        ? userTareas[20].image.url
                        : "https://res.cloudinary.com/dd8a6qc45/image/upload/v1708994985/Volunteers/assets/mundo_ka9mlm.png"
                    }
                    alt=""
                  />
                  <img
                    src={
                      userTareas[21] && userTareas[21].image
                        ? userTareas[21].image.url
                        : "https://res.cloudinary.com/dd8a6qc45/image/upload/v1708994985/Volunteers/assets/mundo_ka9mlm.png"
                    }
                    alt=""
                  />
                  <img
                    src={
                      userTareas[24] && userTareas[24].image
                        ? userTareas[24].image.url
                        : "https://res.cloudinary.com/dd8a6qc45/image/upload/v1708994985/Volunteers/assets/mundo_ka9mlm.png"
                    }
                    alt=""
                  />
                  <img
                    src={
                      userTareas[26] && userTareas[26].image
                        ? userTareas[26].image.url
                        : "https://res.cloudinary.com/dd8a6qc45/image/upload/v1708994985/Volunteers/assets/mundo_ka9mlm.png"
                    }
                    alt=""
                  />
                </div>
                <div className="card-imgpng botpng-n1 botpngimg-n1 image-n1">
                  <img
                    src={
                      userTareas[19] && userTareas[19].image
                        ? userTareas[19].image.url
                        : "https://res.cloudinary.com/dd8a6qc45/image/upload/v1708994985/Volunteers/assets/mundo_ka9mlm.png"
                    }
                    alt=""
                  />
                  <img
                    src={
                      userTareas[25] && userTareas[25].image
                        ? userTareas[25].image.url
                        : "https://res.cloudinary.com/dd8a6qc45/image/upload/v1708994985/Volunteers/assets/mundo_ka9mlm.png"
                    }
                    alt=""
                  />
                </div>
                <div className="card-imgpng uppng-n1">
                  <Link to={`/details/${userTareas[21]?._id}`}>
                    <img
                      className="hexagon-1"
                      src="https://i.postimg.cc/xd2xN0gB/bloque-nivel-1-320p.png"
                      alt=""
                    />
                  </Link>
                  <Link to={`/details/${userTareas[23]?._id}`}>
                    <img
                      className="hexagon-2"
                      src="https://i.postimg.cc/xd2xN0gB/bloque-nivel-1-320p.png"
                      alt=""
                    />
                  </Link>
                </div>
                <div className="card-imgpng centerpng-n1">
                  <Link to={`/details/${userTareas[18]?._id}`}>
                    <img
                      className="hexagon-3"
                      src="https://i.postimg.cc/xd2xN0gB/bloque-nivel-1-320p.png"
                      alt=""
                    />
                  </Link>
                  <Link to={`/details/${userTareas[20]?._id}`}>
                    <img
                      className="hexagon-4"
                      src="https://i.postimg.cc/xd2xN0gB/bloque-nivel-1-320p.png"
                      alt=""
                    />
                  </Link>
                  <Link to={`/details/${userTareas[22]?._id}`}>
                    <img
                      className="hexagon-5"
                      src="https://i.postimg.cc/xd2xN0gB/bloque-nivel-1-320p.png"
                      alt=""
                    />
                  </Link>
                  <Link to={`/details/${userTareas[24]?._id}`}>
                    <img
                      className="hexagon-6"
                      src="https://i.postimg.cc/xd2xN0gB/bloque-nivel-1-320p.png"
                      alt=""
                    />
                  </Link>
                  <Link to={`/details/${userTareas[26]?._id}`}>
                    <img
                      className="hexagon-7"
                      src="https://i.postimg.cc/xd2xN0gB/bloque-nivel-1-320p.png"
                      alt=""
                    />
                  </Link>
                </div>
                <div className="card-imgpng botpng-n1">
                  <Link to={`/details/${userTareas[19]?._id}`}>
                    <img
                      className="hexagon-8"
                      src="https://i.postimg.cc/xd2xN0gB/bloque-nivel-1-320p.png"
                      alt=""
                    />
                  </Link>
                  <Link to={`/details/${userTareas[25]?._id}`}>
                    <img
                      className="hexagon-9"
                      src="https://i.postimg.cc/xd2xN0gB/bloque-nivel-1-320p.png"
                      alt=""
                    />
                  </Link>
                </div>
              </div>
            </div>
            <div className="mark-bottom">
              <p className="mark-volunteers">VOLUNTEERS</p>
            </div>
          </article>
          <article className="activity">
            <div className="activity-head">ACTIVIDADES NIVEL 5</div>
            <div className="card-content">
              <div className="activity-text">
                <p className="text-activity">
                  <span className="number-activity">28.</span> Organice una
                  caminata ecológica e invite a familiares y amigos, realice una
                  observación del lugar y registre los mejores momentos.
                </p>
                <p className="text-activity">
                  <span className="number-activity">29.</span> Con apoyo de la
                  comunidad, limpie un lugar invadido por basuras, realice
                  registro fotográfico del antes y el después, describa los
                  residuos recuperados dándoles una disposición final adecuada.
                </p>
                <p className="text-activity">
                  <span className="number-activity">30.</span> Realice
                  investigación sobre la meditación y sus beneficios, ubique un
                  lugar y póngalo en práctica, preferiblemente en la naturaleza.
                </p>
                <p className="text-activity">
                  <span className="number-activity">31.</span> Fotografiar un
                  ave, identificarla mediante la aplicación “Merlin Bird ID” e
                  investigar su importancia ecológica.
                </p>
                <p className="text-activity">
                  <span className="number-activity">32.</span> Por el planeta
                  apagar todas las luces del hogar y desconectar todos los
                  electrodomésticos una hora durante una semana. Evidenciando el
                  proceso diario mediante fotografías.
                </p>
                <p className="text-activity">
                  <span className="number-activity">33.</span> Evitar consumir
                  bebidas azucaradas embotelladas y procesadas durante una
                  semana. Haga un estimado de cuantos litros evitó consumir y
                  cuantifique y escriba la cantidad de azúcar evitado.
                </p>
                <p className="text-activity">
                  <span className="number-activity">34.</span> Implemente
                  alternativas orgánicas en los productos de aseo personal y del
                  hogar en general, para reducir el uso de productos derivados
                  del petróleo, investigue en su localidad.
                </p>
                <p className="text-activity">
                  <span className="number-activity">35.</span> Evitar consumir
                  carne, pollo, pescado o embutidos por una semana. Describa y
                  registre con fotos el menú alternativo diario.
                </p>
                <p className="text-activity">
                  <span className="number-activity">36.</span> Determine cómo su
                  cultura local puede ayudar a la preservación del ambiente por
                  medio de técnicas, costumbres y tradiciones.
                </p>
              </div>
              <div className="activity-images-n2">
                <div className="card-imgpng-n2 uppng-n2 uppngimg-n2 image-n2">
                  <img
                    className="activity-img"
                    src={
                      userTareas[27] && userTareas[27].image
                        ? userTareas[27].image.url
                        : "https://res.cloudinary.com/dd8a6qc45/image/upload/v1708994985/Volunteers/assets/mundo_ka9mlm.png"
                    }
                    alt=""
                  />
                  <img
                    className="activity-img"
                    src={
                      userTareas[33] && userTareas[33].image
                        ? userTareas[33].image.url
                        : "https://res.cloudinary.com/dd8a6qc45/image/upload/v1708994985/Volunteers/assets/mundo_ka9mlm.png"
                    }
                    alt=""
                  />
                  <img
                    className="activity-img"
                    src={
                      userTareas[32] && userTareas[32].image
                        ? userTareas[32].image.url
                        : "https://res.cloudinary.com/dd8a6qc45/image/upload/v1708994985/Volunteers/assets/mundo_ka9mlm.png"
                    }
                    alt=""
                  />
                </div>
                <div className="card-imgpng-n2 centerpng1-n2 centerpng1img-n2 image-n2">
                  <img
                    src={
                      userTareas[28] && userTareas[28].image
                        ? userTareas[28].image.url
                        : "https://res.cloudinary.com/dd8a6qc45/image/upload/v1708994985/Volunteers/assets/mundo_ka9mlm.png"
                    }
                    alt=""
                  />
                  <img
                    src={
                      userTareas[34] && userTareas[34].image
                        ? userTareas[34].image.url
                        : "https://res.cloudinary.com/dd8a6qc45/image/upload/v1708994985/Volunteers/assets/mundo_ka9mlm.png"
                    }
                    alt=""
                  />
                </div>

                <div className="card-imgpng-n2 centerpng2-n2 image-n2">
                  <img
                    src={
                      userTareas[32] && userTareas[32].image
                        ? userTareas[32].image.url
                        : "https://res.cloudinary.com/dd8a6qc45/image/upload/v1708994985/Volunteers/assets/mundo_ka9mlm.png"
                    }
                    alt=""
                  />
                </div>
                <div className="card-imgpng-n2 centerpng3-n2 centerpng3img-n2 image-n2">
                  <img
                    src={
                      userTareas[29] && userTareas[29].image
                        ? userTareas[29].image.url
                        : "https://res.cloudinary.com/dd8a6qc45/image/upload/v1708994985/Volunteers/assets/mundo_ka9mlm.png"
                    }
                    alt=""
                  />
                  <img
                    src={
                      userTareas[31] && userTareas[31].image
                        ? userTareas[31].image.url
                        : "https://res.cloudinary.com/dd8a6qc45/image/upload/v1708994985/Volunteers/assets/mundo_ka9mlm.png"
                    }
                    alt=""
                  />
                </div>
                <div className="card-imgpng-n2 botpng-n2 image-n2">
                  <img
                    src={
                      userTareas[30] && userTareas[30].image
                        ? userTareas[30].image.url
                        : "https://res.cloudinary.com/dd8a6qc45/image/upload/v1708994985/Volunteers/assets/mundo_ka9mlm.png"
                    }
                    alt=""
                  />
                </div>
                <div className="card-imgpng-n2 uppng-n2">
                  <Link to={`/details/${userTareas[27]?._id}`}>
                    <img
                      className="hexagon-1"
                      src="https://i.postimg.cc/xjmcdnJK/bloque-nivel-2.png"
                      alt=""
                    />
                  </Link>
                  <Link to={`/details/${userTareas[33]?._id}`}>
                    <img
                      className="hexagon-2"
                      src="https://i.postimg.cc/xjmcdnJK/bloque-nivel-2.png"
                      alt=""
                    />
                  </Link>
                  <Link to={`/details/${userTareas[32]?._id}`}>
                    <img
                      className="hexagon-3"
                      src="https://i.postimg.cc/xjmcdnJK/bloque-nivel-2.png"
                      alt=""
                    />
                  </Link>
                </div>
                <div className="card-imgpng-n2 centerpng1-n2">
                  <Link to={`/details/${userTareas[28]?._id}`}>
                    <img
                      className="hexagon-4"
                      src="https://i.postimg.cc/xjmcdnJK/bloque-nivel-2.png"
                      alt=""
                    />
                  </Link>
                  <Link to={`/details/${userTareas[34]?._id}`}>
                    <img
                      className="hexagon-5"
                      src="https://i.postimg.cc/xjmcdnJK/bloque-nivel-2.png"
                      alt=""
                    />
                  </Link>
                </div>
                <div className="card-imgpng-n2 centerpng2-n2">
                  <Link to={`/details/${userTareas[32]?._id}`}>
                    <img
                      className="hexagon-6"
                      src="https://i.postimg.cc/xjmcdnJK/bloque-nivel-2.png"
                      alt=""
                    />
                  </Link>
                </div>
                <div className="card-imgpng-n2 centerpng3-n2">
                  <Link to={`/details/${userTareas[29]?._id}`}>
                    <img
                      className="hexagon-7"
                      src="https://i.postimg.cc/xjmcdnJK/bloque-nivel-2.png"
                      alt=""
                    />
                  </Link>
                  <Link to={`/details/${userTareas[31]?._id}`}>
                    <img
                      className="hexagon-8"
                      src="https://i.postimg.cc/xjmcdnJK/bloque-nivel-2.png"
                      alt=""
                    />
                  </Link>
                </div>
                <div className="card-imgpng-n2 botpng-n2">
                  <Link to={`/details/${userTareas[30]?._id}`}>
                    <img
                      className="hexagon-9"
                      src="https://i.postimg.cc/xjmcdnJK/bloque-nivel-2.png"
                      alt=""
                    />
                  </Link>
                </div>
              </div>
            </div>
            <div className="mark-bottom">
              <p className="mark-volunteers">VOLUNTEERS</p>
            </div>
          </article>
          <article className="activity">
            <div className="activity-head">ACTIVIDADES NIVEL 6</div>
            <div className="card-content">
              <div className="activity-text">
                <p className="text-activity">
                  <span className="number-activity">37.</span> Por la seguridad
                  alimentaria, inicia un huerto orgánico casero, recuperando
                  semillas y esquejes de plantas usadas en la cocina como
                  cebolla, ajo, tomate, pimentón, entre otros. Describa el
                  proceso y registre mediante fotografías.
                </p>
                <p className="text-activity">
                  <span className="number-activity">38.</span> Idear método para
                  reducir consumo de agua en el hogar. Determine el ahorro
                  mensual, llenando la matriz anexada. Registre todo el proceso
                  mediante fotografías.
                </p>
                <p className="text-activity">
                  <span className="number-activity">39.</span> Construir un
                  artículo funcional y estético con materiales reciclados.
                  Describa y registre todo el proceso con fotografías.
                </p>
                <p className="text-activity">
                  <span className="number-activity">40.</span> Investigue
                  exhaustivamente la importancia de las abejas y polinizadores
                  nativos. Escriba y socialice su opinión respecto a la
                  conservación en una comunidad, registre su socialización.
                </p>
                <p className="text-activity">
                  <span className="number-activity">41.</span> Analizar e
                  investigar sobre el derecho de petición según la Ley 1755 de
                  2015, realice un resumen de lo aprendido en la descripción.
                </p>
                <p className="text-activity">
                  <span className="number-activity">42.</span> Realizar
                  investigación exhaustiva sobre cambio climático. Analice sus
                  alcances y plantee estrategias para la adaptación.
                </p>
                <p className="text-activity">
                  <span className="number-activity">43.</span> Fotografiar un
                  paisaje natural y describir sus servicios Ecosistemicos
                  mediante un análisis de los beneficios que provee a la
                  comunidad.
                </p>
                <p className="text-activity">
                  <span className="number-activity">44.</span> Investigar
                  ampliamente acerca de la procrastinación y determine
                  estrategias para evitarla con un ejemplo propio, registre su
                  experiencia.
                </p>
                <p className="text-activity">
                  <span className="number-activity">45.</span> Proponer un plan
                  para la reducción de impactos ambientales para su cuenca
                  hidrográfica, teniendo en cuenta las necesidades de la
                  comunidad y la protección de los recursos naturales. registre
                  su experiencia.
                </p>
              </div>
              <div className="activity-images-n3">
                <div className="card-imgpng-n3 uppng-n3 uppngimg-n3 image-n3">
                  <img
                    className="activity-img"
                    src={
                      userTareas[41] && userTareas[41].image
                        ? userTareas[41].image.url
                        : "https://res.cloudinary.com/dd8a6qc45/image/upload/v1708994985/Volunteers/assets/mundo_ka9mlm.png"
                    }
                    alt=""
                  />
                </div>
                <div className="card-imgpng-n3 centerpng1-n3 centerpng1img-n3 image-n3">
                  <img
                    className="activity-img"
                    src={
                      userTareas[37] && userTareas[37].image
                        ? userTareas[37].image.url
                        : "https://res.cloudinary.com/dd8a6qc45/image/upload/v1708994985/Volunteers/assets/mundo_ka9mlm.png"
                    }
                    alt=""
                  />
                  <img
                    className="activity-img"
                    src={
                      userTareas[42] && userTareas[42].image
                        ? userTareas[42].image.url
                        : "https://res.cloudinary.com/dd8a6qc45/image/upload/v1708994985/Volunteers/assets/mundo_ka9mlm.png"
                    }
                    alt=""
                  />
                </div>
                <div className="card-imgpng-n3 centerpng2-n3 centerpng2img-n3 image-n3">
                  <img
                    src={
                      userTareas[36] && userTareas[36].image
                        ? userTareas[36].image.url
                        : "https://res.cloudinary.com/dd8a6qc45/image/upload/v1708994985/Volunteers/assets/mundo_ka9mlm.png"
                    }
                    alt=""
                  />
                  <img
                    src={
                      userTareas[40] && userTareas[40].image
                        ? userTareas[40].image.url
                        : "https://res.cloudinary.com/dd8a6qc45/image/upload/v1708994985/Volunteers/assets/mundo_ka9mlm.png"
                    }
                    alt=""
                  />

                  <img
                    src={
                      userTareas[44] && userTareas[44].image
                        ? userTareas[44].image.url
                        : "https://res.cloudinary.com/dd8a6qc45/image/upload/v1708994985/Volunteers/assets/mundo_ka9mlm.png"
                    }
                    alt=""
                  />
                </div>
                <div className="card-imgpng-n3 centerpng3-n3 centerpng3img-n3 image-n3">
                  <img
                    src={
                      userTareas[38] && userTareas[38].image
                        ? userTareas[38].image.url
                        : "https://res.cloudinary.com/dd8a6qc45/image/upload/v1708994985/Volunteers/assets/mundo_ka9mlm.png"
                    }
                    alt=""
                  />
                  <img
                    src={
                      userTareas[43] && userTareas[43].image
                        ? userTareas[43].image.url
                        : "https://res.cloudinary.com/dd8a6qc45/image/upload/v1708994985/Volunteers/assets/mundo_ka9mlm.png"
                    }
                    alt=""
                  />
                </div>
                <div className="card-imgpng-n3 botpng-n3 image-n3">
                  <img
                    src={
                      userTareas[39] && userTareas[39].image
                        ? userTareas[39].image.url
                        : "https://res.cloudinary.com/dd8a6qc45/image/upload/v1708994985/Volunteers/assets/mundo_ka9mlm.png"
                    }
                    alt=""
                  />
                </div>
                <div className="card-imgpng-n3 uppng-n3">
                  <Link to={`/details/${userTareas[41]?._id}`}>
                    <img
                      className="hexagon-1"
                      src="https://i.postimg.cc/3JHW4Vmz/bloque-nivel-3.png"
                      alt=""
                    />
                  </Link>
                </div>
                <div className="card-imgpng-n3 centerpng1-n3">
                  <Link to={`/details/${userTareas[27]?._id}`}>
                    <img
                      className="hexagon-2"
                      src="https://i.postimg.cc/3JHW4Vmz/bloque-nivel-3.png"
                      alt=""
                    />
                  </Link>
                  <Link to={`/details/${userTareas[42]?._id}`}>
                    <img
                      className="hexagon-3"
                      src="https://i.postimg.cc/3JHW4Vmz/bloque-nivel-3.png"
                      alt=""
                    />
                  </Link>
                </div>
                <div className="card-imgpng-n3 centerpng2-n3">
                  <Link to={`/details/${userTareas[36]?._id}`}>
                    <img
                      className="hexagon-4"
                      src="https://i.postimg.cc/3JHW4Vmz/bloque-nivel-3.png"
                      alt=""
                    />
                  </Link>
                  <Link to={`/details/${userTareas[40]?._id}`}>
                    <img
                      className="hexagon-5"
                      src="https://i.postimg.cc/3JHW4Vmz/bloque-nivel-3.png"
                      alt=""
                    />
                  </Link>
                  <Link to={`/details/${userTareas[44]?._id}`}>
                    <img
                      className="hexagon-6"
                      src="https://i.postimg.cc/3JHW4Vmz/bloque-nivel-3.png"
                      alt=""
                    />
                  </Link>
                </div>
                <div className="card-imgpng-n3 centerpng3-n3">
                  <Link to={`/details/${userTareas[38]?._id}`}>
                    <img
                      className="hexagon-7"
                      src="https://i.postimg.cc/3JHW4Vmz/bloque-nivel-3.png"
                      alt=""
                    />
                  </Link>
                  <Link to={`/details/${userTareas[43]?._id}`}>
                    <img
                      className="hexagon-8"
                      src="https://i.postimg.cc/3JHW4Vmz/bloque-nivel-3.png"
                      alt=""
                    />
                  </Link>
                </div>
                <div className="card-imgpng-n3 botpng-n3">
                  <Link to={`/details/${userTareas[39]?._id}`}>
                    <img
                      className="hexagon-9"
                      src="https://i.postimg.cc/3JHW4Vmz/bloque-nivel-3.png"
                      alt=""
                    />
                  </Link>
                </div>
              </div>
            </div>
            <div className="mark-bottom">
              <p className="mark-volunteers">VOLUNTEERS</p>
            </div>
          </article>
          <div className="pdf">
            {user.complete === 3 ? (
              <Link
                className="second-buttom certificado-buttom"
                id="certificado"
                to={`/certificado/${user._id}`}
              >
                OBTEN TU CERTIFICADO
              </Link>
            ) : (
              <>
                <div className="note-admin">{user?.adminNote}</div>
                <button
                  className="second-buttom certificado-buttom"
                  onClick={(e) => {
                    handleSubmit(e);
                  }}
                >
                  Enviar Terminado
                </button>
              </>
            )}
          </div>
        </section>
      </div>
    </main>
  );
};

export default Account;
