import React from "react";

import "../../styles/dashboard.css";

const Dates = () => {
  return (
    <div className="button-container">
      <button>2022</button>
      <button>2023</button>
    </div>
  );
};

export default Dates;
