import axios from "axios";

import { apiURL } from "./apiConfig";

export const getActivitiesRequest = async () => {
  return await axios.get(`${apiURL}/api/activities`);
};

export const getActivityRequest = async (id) => {
  try {
    return await axios.get(`${apiURL}/api/activities/` + id);
  } catch (error) {
    throw error.response.data.error;
  }
};

export const postActivityRequest = async (activity) => {
  const token = localStorage.getItem("token");
  try {
    return await axios.post(`${apiURL}/api/activities`, activity, {
      headers: {
        Authorization: token,
      },
    });
  } catch (error) {
    throw error.response.data.error;
  }
};

export const deleteActivityRequest = async (id) => {
  const token = localStorage.getItem("token");
  return await axios.delete(`${apiURL}/api/activities/` + id, {
    headers: {
      Authorization: token,
    },
  });
};

export const updateActivityRequest = async (id, activity) => {
  const token = localStorage.getItem("token");
  return await axios.put(`${apiURL}/api/activities/${id}`, activity, {
    headers: {
      Authorization: token,
    },
  });
};
