import axios from "axios";

import { apiURL } from "./apiConfig";

const token = localStorage.getItem("token");

export const getCommentsAllRequest = async () =>
  await axios.get(`${apiURL}/api/tareas/comment/all`);

export const getCommentsTareaRequest = async (idTarea) =>
  await axios.get(`${apiURL}/api/tareas/${idTarea}/comment/`);

export const getCommentRequest = async (idComment) => {
  return await axios.get(`${apiURL}/api/tareas/comment/${idComment}`);
};

export const createCommentRequest = async (comment) => {
  const token = localStorage.getItem("token");
  try {
    return await axios.post(`${apiURL}/api/tareas/comment/`, comment, {
      headers: {
        Authorization: token,
      },
    });
  } catch (error) {
    console.log(error);
    throw error.response.data.message;
  }
};

export const deleteCommentRequest = async (idComment) => {
  const token = localStorage.getItem("token");
  try {
    return await axios.delete(`${apiURL}/api/tareas/comment/${idComment}`, {
      headers: {
        Authorization: token,
      },
    });
  } catch (error) {
    throw error.response.data;
  }
};

export const updateCommentRequest = async (idComment, comment) => {
  return await axios.put(`${apiURL}/api/tareas/comment/${idComment}`, comment, {
    headers: {
      Authorization: token,
    },
  });
};
