import React, { useEffect, useRef, useState } from "react";
import { useParams, Link, useNavigate, useLocation } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { toast } from "react-hot-toast";

import { useTareas } from "../../context/providers/tareasContext";
import { Spinner } from "../../components/ui/Spinner";
import OldComments from "../../components/ui/OldComments";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { faAnglesLeft } from "@fortawesome/free-solid-svg-icons";
import { useActivities } from "../../context/providers/activitiesContext";

import("../../styles/details.css");

const Details = () => {
  const { tareaId, getTarea, updateTarea, isLoading } = useTareas();

  const { activities } = useActivities();

  const [tarea, setTarea] = useState({
    title: "",
    description: "",
    image: null,
  });

  const [imagePreview, setImagePreview] = useState("");

  const params = useParams();
  const navigate = useNavigate();

  const start = async () => {
    try {
      await getTarea(params.id);
      setTarea(tareaId);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    start();
  }, []);


  const handleGoBack = () =>{
    navigate(-1);
  }

  const handleChange = (e) => {
    if (e.target.name === "image") {
      const file = e.target.files[0];
      setTarea({ ...tarea, [e.target.name]: file });
      setImagePreview(URL.createObjectURL(file));
    } else {
      setTarea({ ...tarea, [e.target.name]: e.target.value });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const uploadTarea = await updateTarea(params.id, tarea);
      if (uploadTarea) {
        toast.success("Tarea actualizada exitosamente!", {
          position: "bottom-right",
        });
        handleGoBack();
      } else {
        toast.error("La tarea no fue actualizada", {
          position: "bottom-right",
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <main>
      <section className="section-details">
        {isLoading ? (
          Spinner
        ) : (
          <>
            <article className="article-details">
              <img
                className="imagen-fondo-tarea"
                src={
                  tarea?.image && tarea.image.url
                    ? tarea.image.url
                    : "https://res.cloudinary.com/dd8a6qc45/image/upload/v1690574586/mundo_ldar8i.png"
                }
                alt="Imagen de tarea"
              />
              <div className="details-container-update-tarea">
                <div className="title-details-container">
                  <h1>{activities[tareaId?.number - 1]?.title}</h1>
                  <p>{activities[tareaId?.number - 1]?.description}</p>
                </div>
                <div className="image-details-container">
                  <img
                    className="detail-image"
                    src={
                      imagePreview ||
                      (tareaId && tareaId?.image?.url) ||
                      "https://res.cloudinary.com/dd8a6qc45/image/upload/v1690574586/mundo_ldar8i.png"
                    }
                    alt="Imagen"
                  />
                </div>

                <Formik
                  initialValues={tarea}
                  validationSchema={Yup.object({
                    title: Yup.string(),
                    description: Yup.string(),
                  })}
                  onSubmit={handleSubmit}
                  enableReinitialize={true}
                >
                  <Form className="form-details" onSubmit={handleSubmit}>
                    <div className="input-label-form">
                      <label htmlFor="image">Imagen</label>
                      <input
                        className="second-buttom input-image-details"
                        name="image"
                        type="file"
                        onChange={handleChange}
                      />
                    </div>

                    <div className="input-label-form">
                      <label htmlFor="description">Descripcion</label>

                      <Field
                        component="textarea"
                        className="input-form input-form-un"
                        name="description"
                        value={tarea?.description}
                        onChange={handleChange}
                      />
                      <ErrorMessage component="p" name="description" />
                    </div>
                    <div className="buttons-details-container">
                      <Link className="buttom-back-details" onClick={handleGoBack}>
                        <FontAwesomeIcon icon={faAnglesLeft} />
                      </Link>

                      <button className="buttom-send-details" type="submit">
                        {isLoading ? (
                          "Cargando..."
                        ) : (
                          <FontAwesomeIcon icon={faCheck} />
                        )}
                      </button>
                    </div>
                  </Form>
                </Formik>
                <h2>Comentarios</h2>
                <OldComments tarea={tareaId} comments={tarea?.comment}/>
              </div>
            </article>
          </>
        )}
      </section>
    </main>
  );
};

export default Details;
