import React from "react";

const OdsActivity16 = () => {
  return (
    <div className="ods-activities-container">
      <h2>ODS ACTIVIDAD 16:</h2>
      <div className="ods-activity-card">
        <div className="ods-activity-image">
          <img
            src="https://res.cloudinary.com/dd8a6qc45/image/upload/v1722645233/Volunteers/assets/ODS/2_bwktp1.png"
            alt="Numero ODS"
          />
        </div>
        <p>
          <span>ODS 2: Hambre cero: (Meta 2.2) </span> <br />
          Consumir menos bebidas azucaradas contribuye a mejorar la nutrición al
          reducir el consumo de calorías vacías y promover hábitos alimentarios
          más saludables.
        </p>
      </div>
      <div className="ods-activity-card">
        <div className="ods-activity-image">
          <img
            src="https://res.cloudinary.com/dd8a6qc45/image/upload/v1722645231/Volunteers/assets/ODS/3_tqqywb.png"
            alt="Numero ODS"
          />
        </div>
        <p>
          <span>ODS 3: Salud y bienestar: (Meta 3.4) </span> <br />
          Reducir el consumo de bebidas azucaradas ayuda a prevenir enfermedades
          no transmisibles como la diabetes tipo 2, la obesidad y enfermedades
          cardiovasculares.
        </p>
      </div>
      <div className="ods-activity-card">
        <div className="ods-activity-image">
          <img
            src="https://res.cloudinary.com/dd8a6qc45/image/upload/v1722645230/Volunteers/assets/ODS/6_mwuiqs.png"
            alt="Numero ODS"
          />
        </div>
        <p>
          <span>ODS 6: Agua limpia y saneamiento: (Meta 6.3) </span> <br />
          La producción de bebidas azucaradas consume grandes cantidades de agua
          y genera residuos, reducir su consumo puede disminuir la demanda de
          agua y la contaminación asociada.
        </p>
      </div>
      <div className="ods-activity-card">
        <div className="ods-activity-image">
          <img
            src="https://res.cloudinary.com/dd8a6qc45/image/upload/v1722645225/Volunteers/assets/ODS/12_totmyp.png"
            alt="Numero ODS"
          />
        </div>
        <p>
          <span>ODS 12: Producción y consumo responsables: (Meta 12.8) </span>{" "}
          <br />
          Promover la reducción del consumo de bebidas azucaradas implica
          fomentar prácticas de consumo más responsables y saludables.
        </p>
      </div>
      <div className="ods-activity-card">
        <div className="ods-activity-image">
          <img
            src="https://res.cloudinary.com/dd8a6qc45/image/upload/v1722645225/Volunteers/assets/ODS/13_tmtnkh.png"
            alt="Numero ODS"
          />
        </div>
        <p>
          <span>ODS 13: Acción por el clima: (Meta 13.2) </span> <br />
          La producción y distribución de bebidas azucaradas generan emisiones
          de gases de efecto invernadero. Reducir su consumo puede contribuir a
          disminuir la huella de carbono.
        </p>
      </div>
    </div>
  );
};

export default OdsActivity16;
