import React from "react";

const OdsActivity17 = () => {
  return (
    <div className="ods-activities-container">
      <h2>ODS ACTIVIDAD 17:</h2>
      <div className="ods-activity-card">
        <div className="ods-activity-image">
          <img
            src="https://res.cloudinary.com/dd8a6qc45/image/upload/v1722645233/Volunteers/assets/ODS/2_bwktp1.png"
            alt="Numero ODS"
          />
        </div>
        <p>
          <span>ODS 2: Hambre cero: (Meta 2.3) </span> <br />
          Las abejas mejoran la producción de cultivos a través de la
          polinización, aumentando la productividad agrícola y, por ende, los
          ingresos de los agricultores.
        </p>
      </div>
      <div className="ods-activity-card">
        <div className="ods-activity-image">
          <img
            src="https://res.cloudinary.com/dd8a6qc45/image/upload/v1722645231/Volunteers/assets/ODS/3_tqqywb.png"
            alt="Numero ODS"
          />
        </div>
        <p>
          <span>ODS 3: Salud y bienestar: (Meta 3.9) </span> <br />
          Las abejas contribuyen a la producción de alimentos nutritivos que son
          esenciales para una dieta saludable. Además, prácticas agrícolas
          sostenibles que protegen a las abejas pueden reducir el uso de
          pesticidas dañinos.
        </p>
      </div>
      <div className="ods-activity-card">
        <div className="ods-activity-image">
          <img
            src="https://res.cloudinary.com/dd8a6qc45/image/upload/v1722645225/Volunteers/assets/ODS/12_totmyp.png"
            alt="Numero ODS"
          />
        </div>
        <p>
          <span>ODS 12: Producción y consumo responsables: (Meta 12.2) </span>{" "}
          <br />
          Promover la salud de las abejas y la polinización natural puede
          reducir la dependencia de métodos agrícolas insostenibles y mejorar la
          eficiencia en el uso de los recursos naturales.
        </p>
      </div>
      <div className="ods-activity-card">
        <div className="ods-activity-image">
          <img
            src="https://res.cloudinary.com/dd8a6qc45/image/upload/v1722645225/Volunteers/assets/ODS/13_tmtnkh.png"
            alt="Numero ODS"
          />
        </div>
        <p>
          <span>ODS 13: Acción por el clima: (Meta 13.2) </span> <br />
          Las abejas y otros polinizadores son sensibles a los cambios
          climáticos. Proteger a las abejas implica también la adaptación y
          mitigación del cambio climático, ya que los ecosistemas saludables son
          más resilientes a los impactos climáticos.
        </p>
      </div>
      <div className="ods-activity-card">
        <div className="ods-activity-image">
          <img
            src="https://res.cloudinary.com/dd8a6qc45/image/upload/v1722645225/Volunteers/assets/ODS/15_hd4qmx.png"
            alt="Numero ODS"
          />
        </div>
        <p>
          <span>ODS 15: Vida de ecosistemas terrestres: (Meta 15.5) </span>{" "}
          <br />
          La protección de las abejas es fundamental para mantener la
          biodiversidad y la salud de los ecosistemas terrestres. Las abejas son
          esenciales para la polinización de plantas silvestres, que a su vez
          sostienen una gran diversidad de vida.
        </p>
      </div>
    </div>
  );
};

export default OdsActivity17;
