import React, { useEffect, useState } from "react";
import { useCode } from "../context/providers/codeContext";
import { Spinner } from "../components/ui/Spinner";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelopeOpenText,
  faTrashCan,
} from "@fortawesome/free-solid-svg-icons";
import toast from "react-hot-toast";
import PDF from "../components/PDF";
import { PDFDownloadLink } from "@react-pdf/renderer";

const CodeAdmin = () => {
  const { isLoading, codes, loadCodes, createCode, removeCode, errorMessage } =
    useCode();

  const handleSubmit = async () => {
    try {
      const result = await createCode();
      loadCodes();
      console.log(result);
    } catch (error) {}
  };

  const handleDelete = async (e, id) => {
    e.preventDefault();
    try {
      const result = await removeCode(id);
      if (result) {
        loadCodes();
        toast.success("Codigo eliminado con exito!", {
          position: "bottom-right",
        });
      }
    } catch (error) {
      console.log(error);
      console.log(errorMessage);
    }
  };

  useEffect(() => {
    loadCodes();
  }, []);

  return (
    <main className="main-admin-panel">
      {isLoading ? (
        Spinner
      ) : (
        <section className="section-activities-admin">
          <h1>Codigos Volunteers</h1>
          <div className="title-card-admin">
            <Link to="/admin-panel">Usuarios</Link>
            <Link to="/activities-admin">Actividades</Link>
            <h2>CODIGOS</h2>
          </div>
          <Link
            onClick={() => {
              handleSubmit();
            }}
          >
            Agregar...
          </Link>
          <div className="content-admin-card">
            <div className="table">
              <table className="table-code-admin">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Codigo</th>
                    <th>Usuario</th>
                    <th>QR</th>
                    <th>img</th>
                    <th>Accion</th>
                  </tr>
                </thead>
                <tbody>
                  {codes?.map((code, index) => (
                    <tr key={code._id}>
                      <td className="td-number-activity">{index + 1}</td>
                      <td>{code.code}</td>
                      <td>{code.user?.name}</td>
                      <td className="svgQR-container">
                        <div
                          className="svgQR"
                          dangerouslySetInnerHTML={{ __html: code.svgQR }}
                        />
                      </td>
                      <td></td>

                      <td className="action-card-admin">
                        <div className="button-panel-admin-container">
                          {!code.user?.name ? (
                            <div>
                              <button
                                className="button-panel-admin-activity"
                                onClick={(e) => {
                                  handleDelete(e, code._id);
                                }}
                              >
                                <FontAwesomeIcon icon={faTrashCan} />
                              </button>
                              <div>
                                <PDFDownloadLink
                                  document={<PDF QR={code.svgQR} />}
                                  fileName="QR-Volunteers.pdf"
                                >
                                  {({ loading, url, error, blob }) =>
                                    loading ? (
                                      <button>Cargando...</button>
                                    ) : (
                                      <button>
                                        <FontAwesomeIcon
                                          icon={faEnvelopeOpenText}
                                        />
                                      </button>
                                    )
                                  }
                                </PDFDownloadLink>
                              </div>
                              {/* <button
                                className="button-panel-admin-activity"
                                onClick={(e) => {
                                  // generateDocument(e, code.svgQR);
                                }}
                              >
                                <FontAwesomeIcon icon={faEnvelopeOpenText} />
                              </button> */}
                            </div>
                          ) : (
                            <></>
                          )}
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </section>
      )}
    </main>
  );
};

export default CodeAdmin;
