import React, { useEffect, useState } from "react";
import "../../styles/enter.css";

const Enter = () => {
  const [opened, setOpened] = useState(false);

  const start = () => {
    const timeOutId = setTimeout(() => {
      handleOpen();
    }, 2000);
    return () => clearTimeout(timeOutId);
  };

  const handleOpen = () => {
    setOpened(true);
    setTimeout(() => {
      document.getElementById("portada").style.display = "none";
    }, 1000);
  };

  useEffect(() => {
    start();
  }, []);

  return (
    <div className={`envelope ${opened ? "opened" : ""}`} id="portada">
      <div className="flap" id="flapTop">
        <div className="text-enter-container">
          <div className="text-enter-container">
            <h2>ENTRENAMIENTO HOLISTICO DE ACTIVISMO AMBIENTAL</h2>
          </div>
        </div>

        <button className="open-button" onClick={handleOpen}>
          <div className="button-icon-logo">
            <img
              src="https://res.cloudinary.com/dd8a6qc45/image/upload/v1708784141/Volunteers/assets/175_Icono_Volunteers_nio4cr.png"
              alt="Logo"
            />
          </div>
        </button>
      </div>
      <div className="flap-down" id="flapBottom"></div>
    </div>
  );
};
export default Enter;
