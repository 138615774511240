import { createContext, useContext, useReducer } from "react";

import {
  deleteCodeRequest,
  getCodeRequest,
  getCodesRequest,
  postCodeRequest,
  putCodeRequest,
  verifyCodeRequest,
} from "../../api/codes";
import { codeReducer, initialState } from "../reducer/codeReducer";
import { codeActions } from "../actions/codeActions";

export const codeContext = createContext(initialState);

export const useCode = () => {
  const context = useContext(codeContext);
  return context;
};

export const CodeProvider = ({ children }) => {
  const [state, dispatch] = useReducer(codeReducer, initialState);

  const loadCodes = async () => {
    dispatch({ type: codeActions.LOAD_CODES });
    try {
      const res = await getCodesRequest();
      if (res.data) {
        dispatch({
          type: codeActions.LOAD_CODES_SUCCESS,
          payload: res.data,
        });
      }
    } catch (error) {
      dispatch({
        type: codeActions.LOAD_CODES_ERROR,
        payload: error,
      });
    }
  };

  const getCode = async (id) => {
    dispatch({ type: codeActions.GET_CODE });
    try {
      const res = await getCodeRequest(id);
      if (res.data) {
        dispatch({
          type: codeActions.GET_CODE_SUCCESS,
          payload: res.data,
        });
      }
    } catch (error) {
      dispatch({
        type: codeActions.GET_CODE_ERROR,
        payload: error,
      });
    }
  };

  const createCode = async () => {
    dispatch({ type: codeActions.SAVE_CODE });
    try {
      const res = await postCodeRequest();
      if (res.data) {
        dispatch({
          type: codeActions.SAVE_CODE_SUCCESS,
          payload: res.data,
        });
      }
    } catch (error) {
      dispatch({
        type: codeActions.SAVE_CODE_ERROR,
        payload: error,
      });
    }
  };

  const updateCode = async (id, code) => {
    dispatch({ type: codeActions.UPDATE_CODE });
    try {
      const res = await putCodeRequest(id, code);
      if (res.data) {
        dispatch({
          type: codeActions.UPDATE_CODE_SUCCESS,
          payload: res.data,
        });
      }
    } catch (error) {
      dispatch({
        type: codeActions.UPDATE_CODE_ERROR,
        payload: error,
      });
    }
  };

  const removeCode = async (id) => {
    dispatch({ type: codeActions.DELETE_CODE });
    try {
      const res = await deleteCodeRequest(id);
      if (res) {
        dispatch({
          type: codeActions.DELETE_CODE_SUCCESS,
          payload: res.data,
        });
      }
      return res;
    } catch (error) {
      dispatch({
        type: codeActions.DELETE_CODE_ERROR,
        payload: error,
      });
    }
  };

  const verifyCode = async (code) => {
    dispatch({ type: codeActions.VERIFY_CODE });
    try {
      const res = await verifyCodeRequest(code);
      if (res) {
        dispatch({
          type: codeActions.VERIFY_CODE_SUCCESS,
          payload: res.data,
        });
        return res;
      }
    } catch (error) {
      dispatch({
        type: codeActions.VERIFY_CODE_ERROR,
        payload: error,
      });
      throw error;
    }
  };

  const accessCodeOff = async (code) => {
    dispatch({ type: codeActions.VERIFY_CODE });
    try {
      dispatch({
        type: codeActions.VERIFY_CODE_SUCCESS,
        payload: false,
      });
    } catch (error) {
      dispatch({
        type: codeActions.VERIFY_CODE_ERROR,
        payload: error,
      });
      throw error;
    }
  };
  return (
    <codeContext.Provider
      value={{
        ...state,
        loadCodes,
        getCode,
        updateCode,
        createCode,
        removeCode,
        verifyCode,
        accessCodeOff,
      }}
    >
      {children}
    </codeContext.Provider>
  );
};
