export const codeActions = {
  LOAD_CODES: "LOAD_CODES",
  LOAD_CODES_SUCCESS: "LOAD_CODES_SUCCESS",
  LOAD_CODES_ERROR: "LOAD_CODES_ERROR",

  SAVE_CODE: "SAVE_CODE",
  SAVE_CODE_SUCCESS: "SAVE_CODE_SUCCESS",
  SAVE_CODE_ERROR: "SAVE_CODE_ERROR",

  UPDATE_CODE: "UPDATE_CODE",
  UPDATE_CODE_SUCCESS: "UPDATE_CODE_SUCCESS",
  UPDATE_CODE_ERROR: "UPDATE_CODE_ERROR",

  DELETE_CODE: "DELETE_CODE",
  DELETE_CODE_SUCCESS: "DELETE_CODE_SUCCESS",
  DELETE_CODE_ERROR: "DELETE_CODE_ERROR",

  GET_CODE: "GET_CODE",
  GET_CODE_SUCCESS: "GET_CODE_SUCCESS",
  GET_CODE_ERROR: "GET_CODE_ERROR",

  VERIFY_CODE: "VERIFY_CODE",
  VERIFY_CODE_SUCCESS: "VERIFY_CODE_SUCCESS",
  VERIFY_CODE_ERROR: "VERIFY_CODE_ERROR",

  ACCESS_OF_CODE: "ACCESS_OF_CODE",
  ACCESS_OF_CODE_SUCCESS: "ACCESS_OF_CODE_SUCCESS",
  ACCESS_OF_CODE_ERROR: "ACCESS_OF_CODE_ERROR",
};
