import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useUser } from "../../context/providers/userContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faUser } from "@fortawesome/free-solid-svg-icons";
import { faGear } from "@fortawesome/free-solid-svg-icons";
import { faCircleXmark } from "@fortawesome/free-solid-svg-icons";
import { faAddressCard } from "@fortawesome/free-solid-svg-icons";
import { faComments } from "@fortawesome/free-solid-svg-icons";
import Chat from "../Chat";
import PanelChat from "../PanelChat";
import { useMessages } from "../../context/providers/messagesContext";
import ChatBotRetune from "../Dashboard/ChatBotRetune";
import "../../styles/chatBotRetune.css";

const Footer = ({ socket }) => {
  // const Footer = () => {

  const { isLoggedIn, user, userId } = useUser();
  const { viewChat } = useMessages();

  const [botBool, setBotBool] = useState(true);

  const [chatBool, setChatBool] = useState(false);

  const [viewMenu, setViewMenu] = useState(false);

  const hiddenMenu = () => {
    console.log("hiddenMenu");

    const dropdown = document.getElementById("profile");
    setViewMenu(false);
    dropdown.style.clipPath = "inset(100% 0 0% 100%)";
  };

  const showMenu = () => {
    console.log("showMenu");
    const dropdown = document.getElementById("profile");
    setViewMenu(true);
    dropdown.style.clipPath = "inset(0 0 0 0)";
  };
  const chatView = () => {
    const dropdown = document.getElementById("chat");

    if (!chatBool) {
      dropdown.style.clipPath = "inset(0 0 0 0)";
      setChatBool(true);
    } else {
      dropdown.style.clipPath = "inset(100% 100% 0 0)";
      setChatBool(false);
    }
  };
  const botView = () => {
    const dropdown = document.getElementById("chatBot");
    setBotBool(false);
    dropdown.style.clipPath = "inset(0 0 0 0)";
  };
  const botClose = () => {
    const dropdown = document.getElementById("chatBot");
    setBotBool(true);
    dropdown.style.clipPath = "inset(100% 0 0% 100%)";
  };

  return (
    <footer className="footer-content">
      <Link to="/ods" className="footer-ods-icon">
        <img
          src="https://res.cloudinary.com/dd8a6qc45/image/upload/v1722632276/Volunteers/assets/icon-ods_cx6szs.png"
          alt="Icono ODS"
        />
      </Link>
      {isLoggedIn && (
        <div className="footer-container">
          <div className="icon-menu-footer">
            {viewMenu && (
              <div
                className="buttom-menu-footer-close"
                id="button-menu-close-footer"
                onClick={hiddenMenu}
              >
                <FontAwesomeIcon icon={faCircleXmark} className="open-menu" />
              </div>
            )}
            {!viewMenu && (
              <div
                className="buttom-menu-footer"
                id="button-menu-open-footer"
                onClick={showMenu}
              >
                <FontAwesomeIcon icon={faUser} className="open-menu" />
              </div>
            )}
          </div>
          {/* <div
            className="chat-footer-icon"
            id="button-chat-view"
            onClick={chatView}
          >
            <img
              className="icon-chat-panel"
              src="https://res.cloudinary.com/dd8a6qc45/image/upload/v1712006494/Volunteers/assets/icon-chat-panel-250px_a8mnjz.png"
              alt="Panel Chat"
            />
          </div> */}
          <div className="panel-chat-container" id="chat">
            <PanelChat socket={socket} />
            {/* <PanelChat /> */}
          </div>

          {isLoggedIn ? (
            <div className="dropup-container">
              <ul className="dropup" id="profile">
                <li className="primary-bottom li-menu-footer">
                  <Link to="/account-free" onClick={hiddenMenu}>
                    <FontAwesomeIcon icon={faAddressCard} />
                    <hr />
                    Perfil
                  </Link>
                </li>
                <li className="primary-bottom li-menu-footer">
                  <Link to="/config-perfil" onClick={hiddenMenu}>
                    <FontAwesomeIcon icon={faGear} />
                    <hr />
                    Configurar
                  </Link>
                </li>
              </ul>
            </div>
          ) : (
            <></>
          )}
        </div>
      )}
      {/* <ChatBotRetune /> */}
      {botBool && (
        <img
          src="https://res.cloudinary.com/dd8a6qc45/image/upload/v1721954263/Volunteers/assets/ROBIA_eoiwr4.png"
          className="button-chatbot"
          id="button-chatbot-view"
          onClick={botView}
          alt="icono ChatBot"
        />
      )}
      {!botBool && (
        <img
          src="https://res.cloudinary.com/dd8a6qc45/image/upload/v1721954263/Volunteers/assets/ROBIA_eoiwr4.png"
          className="button-chatbot"
          id="button-chatbot-hidden"
          onClick={botClose}
          alt="icono ChatBot"
        />
      )}

      <iframe
        className="iframe-chatbot"
        id="chatBot"
        src="https://retune.so/share/chat/11ee5794-2bd8-34f0-899f-036ecb8650de/widget"
        title="Chatbot de re:tune.so"
      />
    </footer>
  );
};

export default Footer;
