import React, { useEffect, useRef, useState } from "react";
import { useParams, Link, useNavigate, useLocation } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { toast } from "react-hot-toast";

import { useTareas } from "../../context/providers/tareasContext";
import { Spinner } from "../../components/ui/Spinner";
import OldComments from "../../components/ui/OldComments";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { faAnglesLeft } from "@fortawesome/free-solid-svg-icons";
import { useActivities } from "../../context/providers/activitiesContext";

const ExamDetails = () => {
  const { tareaId, getTarea, updateTarea, isLoading, getTask18, task18 } =
    useTareas();

  const {
    activities,
    loadActivities,
    isLoading: isLoadingActivities,
  } = useActivities();

  const params = useParams();
  const navigate = useNavigate();

  const [quest, setQuest] = useState({});

  useEffect(() => {
    const algo = async () => {
      await getTask18(params.id);
    };
    algo();
  }, []);

  const questions = async () => {
    await loadActivities();
    const question = await activities[17]?.description.split("?");
    if (question) {
      const formattefQuestions = question.map((question) => {
        return `${question.trim()}`;
      });

      setQuest(formattefQuestions);
    }
  };

  const start = async () => {
    try {
      await getTarea(params.id);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    loadActivities();
    start();
    questions();
  }, []);

  const handleGoBack = () => {
    navigate(-1);
  };

  const handleSubmit = async (values) => {
    try {
      let result = {
        title: "",
        description: Object.values(values).join("####"),
        image: null,
      };
      const uploadTarea = await updateTarea(params.id, result);
      await getTask18(params.id);

      if (uploadTarea) {
        toast.success("Tarea Actualizada exitosamente!", {
          position: "bottom-right",
        });
        console.log(uploadTarea);
      } else {
        toast.error("La tarea no fue actualizada", {
          position: "bottom-right",
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <main>
      <section className="section-details">
        {isLoading || isLoadingActivities ? (
          Spinner
        ) : (
          <>
            <article className="article-details">
              <img
                className="imagen-fondo-tarea"
                src="https://cdn.pixabay.com/photo/2016/03/27/18/49/man-1283576_1280.jpg"
                alt="Imagen de tarea"
              />
              <div className="details-container-update-tarea">
                <div className="title-details-container">
                  <h1>{activities[tareaId?.number - 1]?.title}</h1>
                  <Formik
                    initialValues={task18}
                    validationSchema={Yup.object({
                      quest1: Yup.string(),
                      quest2: Yup.string(),
                      quest3: Yup.string(),
                      quest4: Yup.string(),
                      quest5: Yup.string(),
                      quest6: Yup.string(),
                    })}
                    onSubmit={handleSubmit}
                    enableReinitialize={true}
                  >
                    <Form className="form-details">
                      <div className="input-label-form">
                        <label htmlFor={`quest1`}>{`${quest[0]}?`}</label>
                        <Field
                          component="textarea"
                          className="input-form input-form-un"
                          name={`quest1`}
                        />
                        <ErrorMessage component="p" name={`quest1`} />
                      </div>
                      <div className="input-label-form">
                        <label htmlFor={`quest2`}>{`${quest[1]}?`}</label>
                        <Field
                          component="textarea"
                          className="input-form input-form-un"
                          name={`quest2`}
                        />
                        <ErrorMessage component="p" name={`quest2`} />
                      </div>
                      <div className="input-label-form">
                        <label htmlFor={`quest3`}>{`${quest[2]}?`}</label>
                        <Field
                          component="textarea"
                          className="input-form input-form-un"
                          name={`quest3`}
                        />
                        <ErrorMessage component="p" name={`quest3`} />
                      </div>
                      <div className="input-label-form">
                        <label htmlFor={`quest4`}>{`${quest[3]}?`}</label>
                        <Field
                          component="textarea"
                          className="input-form input-form-un"
                          name={`quest4`}
                        />
                        <ErrorMessage component="p" name={`quest4`} />
                      </div>
                      <div className="input-label-form">
                        <label htmlFor={`quest5`}>{`${quest[4]}?`}</label>
                        <Field
                          component="textarea"
                          className="input-form input-form-un"
                          name={`quest5`}
                        />
                        <ErrorMessage component="p" name={`quest5`} />
                      </div>
                      <div className="input-label-form">
                        <label htmlFor={`quest6`}>{`${quest[5]}`}</label>
                        <Field
                          component="textarea"
                          className="input-form input-form-un"
                          name={`quest6`}
                          // value={respuestas.quest1}
                        />
                        <ErrorMessage component="p" name={`quest6`} />
                      </div>
                      <div className="buttons-details-container">
                        <Link
                          className="buttom-back-details"
                          onClick={handleGoBack}
                        >
                          <FontAwesomeIcon icon={faAnglesLeft} />
                        </Link>
                        <button className="buttom-send-details" type="submit">
                          {isLoading ? (
                            "Cargando..."
                          ) : (
                            <FontAwesomeIcon icon={faCheck} />
                          )}
                        </button>
                      </div>
                    </Form>
                  </Formik>
                </div>
                <h2>Comentarios</h2>
                <OldComments tarea={tareaId} />
              </div>
            </article>
          </>
        )}
      </section>
    </main>
  );
};

export default ExamDetails;
