import React, { useEffect } from "react";

import { useActivities } from "../../context/providers/activitiesContext";

import "../../styles/activitiesAdmin.css";
import "../../styles/adminPanel.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useNavigate } from "react-router-dom";
import { faEye, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { Spinner } from "../../components/ui/Spinner";
import { deleteActivityRequest } from "../../api/activities";
import toast from "react-hot-toast";

const ActivitiesAdmin = () => {
  const { isLoadding, activities, loadActivities, getActivity } =
    useActivities();

  useEffect(() => {
    loadActivities();
  }, []);

  const navigate = useNavigate();

  const handleView = async (id) => {
    await getActivity(id);
    navigate(`/edit-activity/${id}`);
  };

  const handleDelete = async (e, id) => {
    e.preventDefault();
    try {
      const activityDeleted = await deleteActivityRequest(id);
      if (activityDeleted) {
        loadActivities();
        toast.success("Actividad eliminada correctamente!", {
          position: "bottom-right",
        });
      }
    } catch (error) {
      toast.error("La actividad no ha sido eliminada!", {
        position: "bottom-right",
      });
      console.log(error);
    }
  };

  return (
    <main className="main-admin-panel">
      {isLoadding ? (
        Spinner
      ) : (
        <section className="section-activities-admin">
          <h1>Actividades Volunteers</h1>
          <div className="title-card-admin">
            <Link to="/admin-panel">Usuarios</Link>
            <h2>ACTIVIDADES</h2>
            <Link to="/codes-admin">Codigos</Link>
          </div>
          <Link to="/new-activity">Agregar...</Link>
          <div className="content-admin-card">
            <div className="table">
              <table>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Titulo</th>
                    <th>Descripcion</th>
                    <th>Accion</th>
                  </tr>
                </thead>
                <tbody>
                  {activities?.map((activity) => (
                    <tr key={activity._id}>
                      <td className="td-number-activity">{activity.number}</td>
                      <td>{activity.title}</td>
                      <td>{activity.description}</td>
                      <td className="action-card-admin">
                        <div className="button-panel-admin-container">
                          <button
                            className="button-panel-admin-activity"
                            onClick={(e) => {
                              handleDelete(e, activity._id);
                            }}
                          >
                            <FontAwesomeIcon icon={faTrashCan} />
                          </button>
                          <button
                            className="button-panel-admin-activity"
                            onClick={() => {
                              handleView(activity._id);
                            }}
                          >
                            <FontAwesomeIcon icon={faEye} />
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </section>
      )}
    </main>
  );
};

export default ActivitiesAdmin;
