import axios from "axios";

import { apiURL } from "./apiConfig";

export const getUsers = async () => {
  return await axios.get(`${apiURL}/api/users`);
};

export const getUserRequest = async (id) => {
  try {
    return await axios.get(`${apiURL}/api/users/` + id);
  } catch (error) {
    console.log(error);
  }
};

export const postSignUpRequest = async (user) => {
  try {
    return await axios.post(`${apiURL}/api/auth/signup`, user, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  } catch (error) {
    console.log(error);
  }
};

export const postSignInRequest = async (user) =>
  await axios.post(`${apiURL}/api/auth/signin`, user);

export const putUserRequest = async (id, user) => {
  const token = localStorage.getItem("token");
  return await axios.put(`${apiURL}/api/auth/profile/${id}`, user, {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: token,
    },
  });
};

export const getProfileRequest = async (token) => {
  return await axios.get(`${apiURL}/api/auth/profile`, {
    headers: {
      Authorization: token,
    },
  });
};

export const getTareasUserRequest = async (id) => {
  try {
    const token = localStorage.getItem("token");
    return await axios.get(`${apiURL}/api/auth/profile/${id}/tareas`, {
      headers: {
        Authorization: token,
      },
    });
  } catch (error) {
    console.log(error);
  }
};

export const getCommentsUserRequest = async (idUser) =>
  await axios.get(`${apiURL}/api/tareas/${idUser}/comment/`);

export const addLikeTarea = async (tareaId) => {
  return await axios.post(`${apiURL}/api/tareas/like/${tareaId}`);
};

export const deleteUserAndTasksRequest = async (idUser) => {
  try {
    const token = localStorage.getItem("token");

    return await axios.delete(`${apiURL}/api/users/${idUser}`, {
      headers: {
        Authorization: token,
      },
    });
  } catch (error) {
    console.log(error);
  }
};

export const emailResetPasswordRequest = async (email) => {
  try {
    return await axios.post(`${apiURL}/api/auth/email-reset-password/`, email);
  } catch (error) {
    console.log(error);
  }
};

export const resetPasswordRequest = async (token) => {
  try {
    var user = { token: token };
    return await axios.post(`${apiURL}/api/auth/reset-password/`, user);
  } catch (error) {
    throw error.response.data;
  }
};

export const updatePasswordRequest = async (id, user) => {
  try {
    return await axios.put(`${apiURL}/api/auth/update-password/${id}`, user);
  } catch (error) {
    throw error.respose.data;
  }
};

export const deleteMyAccountRequest = async (id) => {
  try {
    const token = localStorage.getItem("token");
    const res = await axios.delete(`${apiURL}/api/users/kill-me/${id}`, {
      headers: {
        Authorization: token,
      },
    });
    if (res) {
      return res;
    }
  } catch (error) {
    console.log(error);
  }
};
