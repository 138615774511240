import { faAnglesLeft, faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

import * as Yup from "yup";
import { useActivities } from "../../context/providers/activitiesContext";
import toast from "react-hot-toast";
import { Spinner } from "../../components/ui/Spinner";

const ActivityAdmin = () => {
  const navigate = useNavigate();
  const {
    isLoading,
    postActivity,
    errorMessage,
    getActivity,
    activityId,
    updateActivity,
  } = useActivities();

  const [activity, setActivity] = useState({
    title: "",
    description: "",
    number: "",
  });

  const param = useParams();

  const start = async () => {
    if (param.id) {
      try {
        await getActivity(param.id);
        setActivity(activityId);
        console.log("si");
      } catch (error) {
        console.log(error);
      }
    } else {
      console.log("no tiene param");
    }
  };

  const handleChange = (e) => {
    setActivity({ ...activity, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      var newActivity;
      if (param.id) {
        newActivity = await updateActivity(param.id, activity);
      } else {
        newActivity = await postActivity(activity);
      }
      console.log(newActivity);
      if (newActivity) {
        if (param.id) {
          toast.success(`La tarea ${activity.number} se ha guardado.`, {
            position: "bottom-right",
          });
        } else {
          toast.success(`Se ha creado la tarea ${activity.number}.`, {
            position: "bottom-right",
          });
        }
        navigate("/activities-admin");
      }
    } catch (error) {
      toast.error(errorMessage, {
        position: "bottom-right",
      });
    }
  };

  useEffect(() => {
    start();
  }, []);

  return (
    <main>
      <section className="section-details">
        {isLoading ? (
          Spinner
        ) : (
          <article className="article-details">
            <div className="title-details-container">
              {!param.id ? <h1>NUEVA TAREA</h1> : <h1>EDITAR TAREA</h1>}
            </div>

            <Formik
              initialValues={activity}
              validationSchema={Yup.object({
                title: Yup.string().required("*"),
                description: Yup.string().required("*"),
                number: Yup.number().required("*"),
              })}
              onSubmit={handleSubmit}
              enableReinitialize={true}
            >
              <Form className="form-details" onSubmit={handleSubmit}>
                <div className="input-label-form">
                  <label htmlFor="title">Titulo</label>
                  <Field
                    className="input-form input-form-un"
                    name="title"
                    placeholder="Titulo"
                    onChange={handleChange}
                  />
                  <ErrorMessage component="p" name="title" />
                </div>
                <div className="input-label-form">
                  <label htmlFor="description">Descripcion</label>

                  <Field
                    component="textarea"
                    className="input-form input-form-un"
                    name="description"
                    placeholder="Description"
                    onChange={handleChange}
                  />
                  <ErrorMessage component="p" name="description" />
                </div>
                <div className="input-label-form">
                  <label htmlFor="number">Numero</label>

                  <Field
                    className="input-form input-form-un"
                    name="number"
                    placeholder="Numero"
                    onChange={handleChange}
                  />
                  <ErrorMessage component="p" name="description" />
                </div>
                <div className="buttons-details-container">
                  <Link className="buttom-back-details" to="/activities-admin">
                    <FontAwesomeIcon icon={faAnglesLeft} />
                  </Link>

                  <button className="buttom-send-details" type="submit">
                    {isLoading ? (
                      "Cargando..."
                    ) : (
                      <FontAwesomeIcon icon={faCheck} />
                    )}
                  </button>
                </div>
              </Form>
            </Formik>
          </article>
        )}
      </section>
    </main>
  );
};

export default ActivityAdmin;
