import React, { useEffect } from "react";
import Galery from "../../components/Dashboard/Galery";

import "../../styles/dashboard.css";
import VideosDashboard from "../../components/Dashboard/VideosDashboard";
import Map from "../../components/Dashboard/Map";
import Dates from "../../components/Dashboard/Dates";
import { io } from "socket.io-client";

const DashboardPage = () => {
  useEffect(() =>{
    const socket = io('http://localhost:5200');
    socket.on('connect', () =>{
      console.log("Conectado al servidor io");
    });
    socket.on('message', (data) => {
      console.log("Mensaje recibido: ",data);
    });

    return() =>{
      socket.disconnect();
    }
  },[]);
  return (
    <div className="galery">
      <p>Dashboard</p>
      <div className="dash-galery">
        <Galery />
      </div>
      <div className="dash-galery-videos">
        <VideosDashboard />
      </div>
      <div>
        <Map />
      </div>
      <div>
        <Dates />
      </div>
    </div>
  );
};

export default DashboardPage;
