import React from "react";

import "../../../styles/odsActivities.css";

const OdsActivity1 = () => {
  return (
    <div className="ods-activities-container">
      <h2>ODS ACTIVIDAD 1: </h2>
      <div className="ods-activity-card">
        <div className="ods-activity-image">
          <img
            src="https://res.cloudinary.com/dd8a6qc45/image/upload/v1722645231/Volunteers/assets/ODS/3_tqqywb.png"
            alt="Numero ODS"
          />
        </div>
        <p>
          <span>ODS 3: Salud y Bienestar: (Meta 3.9) </span> <br />
          Reducir sustancialmente el número de muertes y enfermedades producidas
          por productos químicos peligrosos y la contaminación del aire, el agua
          y el suelo. Utilizar medios de transporte alternativos como la
          bicicleta o caminar reduce la contaminación del aire causada por los
          vehículos motorizados, mejorando la calidad del aire y reduciendo
          enfermedades respiratorias y cardiovasculares.
        </p>
      </div>
      <div className="ods-activity-card">
        <div className="ods-activity-image">
          <img
            src="https://res.cloudinary.com/dd8a6qc45/image/upload/v1722645230/Volunteers/assets/ODS/7_gguoxk.png"
            alt="Numero ODS"
          />
        </div>
        <p>
          <span>ODS 7: Energía Asequible y No Contaminante: (Meta 7.3) </span>
          <br />
          Duplicar la tasa mundial de mejora de la eficiencia energética. Medios
          de transporte alternativos, como bicicletas o vehículos eléctricos,
          consumen menos energía comparados con los vehículos tradicionales de
          combustión, contribuyendo a una mayor eficiencia energética y
          reduciendo la dependencia de combustibles fósiles.
        </p>
      </div>
      <div className="ods-activity-card">
        <div className="ods-activity-image">
          <img
            src="https://res.cloudinary.com/dd8a6qc45/image/upload/v1722645227/Volunteers/assets/ODS/8_sxwmwv.png"
            alt="Numero ODS"
          />
        </div>
        <p>
          <span>
            ODS 8: Trabajo Decente y Crecimiento Económico: (Meta 8.4)
          </span>
          <br />
          Mejorar progresivamente la eficiencia global de los recursos en el
          consumo y la producción y procurar desacoplar el crecimiento económico
          de la degradación ambiental. Fomentar el uso de transportes
          alternativos puede generar empleos en sectores relacionados con la
          producción, mantenimiento y gestión de estos medios de transporte.
          Además, al reducir el uso de vehículos motorizados, se disminuye la
          presión sobre los recursos naturales y se promueve un crecimiento
          económico más sostenible.
        </p>
      </div>
      <div className="ods-activity-card">
        <div className="ods-activity-image">
          <img
            src="https://res.cloudinary.com/dd8a6qc45/image/upload/v1722645227/Volunteers/assets/ODS/9_gwz6lw.png"
            alt="Numero ODS"
          />
        </div>
        <p>
          <span>
            ODS 9: Industria, Innovación e Infraestructura: (Meta 9.1)
          </span>{" "}
          <br />
          Desarrollar infraestructuras fiables, sostenibles, resilientes y de
          calidad, incluidas las infraestructuras regionales y transfronterizas.
          Promover el uso de transportes alternativos requiere el desarrollo de
          infraestructuras adecuadas, como carriles bici y estaciones de carga
          para vehículos eléctricos. Esto impulsa la innovación y la
          modernización de las infraestructuras urbanas hacia soluciones más
          sostenibles.
        </p>
      </div>
      <div className="ods-activity-card">
        <div className="ods-activity-image">
          <img
            src="https://res.cloudinary.com/dd8a6qc45/image/upload/v1722645225/Volunteers/assets/ODS/11_qyelnc.png"
            alt="Numero ODS"
          />
        </div>
        <p>
          <span>ODS 11: Ciudades y Comunidades Sostenibles: (Meta 11.2) </span>
          <br />
          Utilizar transportes alternativos ayuda a reducir la congestión del
          tráfico y la contaminación urbana, creando ciudades más sostenibles y
          habitables. También mejora el acceso al transporte sostenible,
          promoviendo la inclusión y la seguridad vial.
        </p>
      </div>
      <div className="ods-activity-card">
        <div className="ods-activity-image">
          <img
            src="https://res.cloudinary.com/dd8a6qc45/image/upload/v1722645225/Volunteers/assets/ODS/12_totmyp.png"
            alt="Numero ODS"
          />
        </div>
        <p>
          <span>ODS 12: Producción y Consumo Responsables: (Meta 12.5)</span>
          <br />
          Reducir considerablemente la generación de desechos mediante
          actividades de prevención, reducción, reciclado y reutilización.
          Relación: Reducir el uso de vehículos motorizados contribuye a
          disminuir la producción de desechos, como piezas de automóviles y
          neumáticos. Además, fomenta el consumo responsable de recursos y la
          reducción de la huella de carbono.
        </p>
      </div>
      <div className="ods-activity-card">
        <div className="ods-activity-image">
          <img
            src="https://res.cloudinary.com/dd8a6qc45/image/upload/v1722645225/Volunteers/assets/ODS/13_tmtnkh.png"
            alt="Numero ODS"
          />
        </div>
        <p>
          <span>ODS 13: Acción por el Clima: (Meta 13.2)</span> <br />
          El uso de transportes alternativos reduce las emisiones de gases de
          efecto invernadero, contribuyendo a la mitigación del cambio
          climático. Promover estos medios de transporte puede ser parte de
          políticas y estrategias nacionales para enfrentar el cambio climático.
        </p>
      </div>
      <div className="ods-activity-card">
        <div className="ods-activity-image">
          <img
            src="https://res.cloudinary.com/dd8a6qc45/image/upload/v1722645225/Volunteers/assets/ODS/15_hd4qmx.png"
            alt="Numero ODS"
          />
        </div>
        <p>
          <span>ODS 15: Vida de Ecosistemas Terrestres: (Meta 15.5)</span>
          <br />
          Menos contaminación del aire y reducción del uso de combustibles
          fósiles ayudan a proteger los ecosistemas terrestres y la
          biodiversidad. La disminución de la contaminación mejora la salud de
          los hábitats naturales.
        </p>
      </div>
      <div className="ods-activity-card">
        <div className="ods-activity-image">
          <img
            src="https://res.cloudinary.com/dd8a6qc45/image/upload/v1722645225/Volunteers/assets/ODS/17_e8x3cm.png"
            alt="Numero ODS"
          />
        </div>
        <p>
          <span>ODS 17: Alianzas para lograr los objetivos: (Meta 17.16)</span>
          <br />
          Promover el uso de medios de transporte alternativos puede involucrar
          colaboraciones entre gobiernos, sector privado y sociedad civil. Estas
          alianzas pueden facilitar el intercambio de conocimientos y recursos
          para implementar soluciones de transporte sostenible a nivel local y
          global.
        </p>
      </div>
    </div>
  );
};

export default OdsActivity1;
