import React, { useState } from "react";
import { useUser } from "../../../context/providers/userContext";
import { Spinner } from "../Spinner";
import * as Yup from "yup";
import OldComments from "../OldComments";

import "../../../styles/adminTarea.css";
import { Link, useParams } from "react-router-dom";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperPlane } from "@fortawesome/free-regular-svg-icons";
import Task18Admin from "../../Task18Admin";

const Tareas = ({ user }) => {
  const { isLoading, updateUserAdmin } = useUser();

  const [us, setUs] = useState({
    adminNote: user?.adminNote || "",
    complete: user?.complete || "",
  });

  const param = useParams();

  const handleChange = (e) => {
    setUs({ ...us, [e.target.name]: e.target.value });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      us.complete = 2;
      const userResponse = await updateUserAdmin(param.id, us);
      if (userResponse) {
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSend = async (e) => {
    e.preventDefault();
    try {
      us.complete = 3;

      const userResponse = await updateUserAdmin(param.id, us);
      if (userResponse) {
        console.log(userResponse);
      }
    } catch (error) {
      console.log(error);
    }
  };
  if (isLoading) {
    return Spinner;
  }
  return (
    <>
      <a href="#nota" className="">
        Calificar
      </a>
      {user?.tareas.map((tarea) => (
        <article key={tarea._id}>
          {tarea.number <= 17 ? (
            <div className="main-content-card-admin">
              <div className="title-activity-admin">
                <h1 className="">{tarea.title}</h1>
              </div>
              <div className="image-activity">
                {tarea.image?.url ? (
                  <Link to={`/image/${encodeURIComponent(tarea.image?.url)}`}>
                    <img src={tarea.image?.url} alt="Imagen Actividad" />
                  </Link>
                ) : (
                  <img
                    src="https://res.cloudinary.com/dd8a6qc45/image/upload/v1690574586/mundo_ldar8i.png"
                    alt="Imagen Actividad"
                  />
                )}
              </div>
              <div className="description-card-tareas">
                <h4>Descripcion:</h4>
                <p>{tarea.description}</p>
              </div>
              {tarea?.comment[0] ? (
                <>
                  <h4 className="comments-activities-admin">Comentarios:</h4>
                  <OldComments comments={tarea.comment} />
                </>
              ) : (
                <></>
              )}
              <hr />
            </div>
          ) : (
            <></>
          )}
          {tarea.number === 18 ? (
            <Task18Admin tarea={tarea}/>
          ):(
            <></>
          )}
        </article>
      ))}
      <div id="nota">
        <div>{user?.adminNote}</div>
        <div className="form-content-card">
          <Formik
            initialValues={us}
            validationSchema={Yup.object({
              andminNote: Yup.string(),
            })}
            onSubmit={handleSubmit}
            enableReinitialize={true}
          >
            <Form onSubmit={handleSubmit}>
              <ErrorMessage
                className="error-message"
                component="p"
                name="adminNote"
              />
              <label htmlFor="adminNote">Editar Anotacion</label>
              <div className="comment-card">
                <Field
                  component="textarea"
                  className="new-comment"
                  name="adminNote"
                  id="adminNote"
                  value={us?.adminNote}
                  onChange={handleChange}
                />
                <button type="submit" className="send-new-comment">
                  <FontAwesomeIcon icon={faPaperPlane} />
                </button>
              </div>
            </Form>
          </Formik>
          <div>
            <h4>Estado:</h4>
            {user?.complete === 0 ? (
              <h5 className="check-null">Sin Registro</h5>
            ) : user?.complete === 1 ? (
              <h5 className="check-review">Por revisar</h5>
            ) : user?.complete === 2 ? (
              <h5 className="check-return">Devuelto</h5>
            ) : user?.complete === 3 ? (
              <h5 className="check-complete">Aprobado</h5>
            ) : (
              <></>
            )}
          </div>
          <button
            className="bottom-send"
            onClick={(e) => {
              handleSend(e);
            }}
          >
            Aprobar Certificado
          </button>
        </div>
      </div>
    </>
  );
};

export default Tareas;
