import { codeActions } from "../actions/codeActions";

export const initialState = {
  isLoading: false,
  codes: [],
  code: null,
  accessCode: false,
  errorMessage: "",
  accessCodeOff: false,
};

export const codeReducer = (state, actions) => {
  const { type, payload } = actions;

  switch (type) {
    case codeActions.LOAD_CODES:
      return {
        ...state,
        isLoading: true,
      };
    case codeActions.LOAD_CODES_SUCCESS:
      return {
        ...state,
        codes: payload,
        isLoading: false,
      };
    case codeActions.LOAD_CODES_ERROR:
      return {
        ...state,
        isLoading: false,
        errorMessage: payload,
      };
    case codeActions.GET_CODE:
      return {
        ...state,
        isLoading: true,
      };
    case codeActions.GET_CODE_SUCCESS:
      return {
        ...state,
        code: payload,
        isLoading: false,
      };
    case codeActions.GET_CODE_ERROR:
      return {
        ...state,
        isLoading: false,
        errorMessage: payload,
      };
    case codeActions.SAVE_CODE:
      return {
        ...state,
        isLoading: true,
      };
    case codeActions.SAVE_CODE_SUCCESS:
      return {
        ...state,
        code: [...state.codes, payload],
        isLoading: false,
      };
    case codeActions.SAVE_CODE_ERROR:
      return {
        ...state,
        isLoading: false,
        errorMessage: payload,
      };
    case codeActions.UPDATE_CODE:
      return {
        ...state,
        isLoading: true,
      };
    case codeActions.UPDATE_CODE_SUCCESS:
      return {
        ...state,
        code: payload,
        isLoading: false,
      };
    case codeActions.UPDATE_CODE_ERROR:
      return {
        ...state,
        isLoading: false,
        errorMessage: payload,
      };
    case codeActions.DELETE_CODE:
      return {
        ...state,
        isLoading: true,
      };
    case codeActions.DELETE_CODE_SUCCESS:
      return {
        ...state,
        codes: state.codes.filter((code) => code._id !== payload),
        isLoading: false,
      };
    case codeActions.DELETE_CODE_ERROR:
      return {
        ...state,
        isLoading: false,
        errorMessage: payload,
      };
    case codeActions.VERIFY_CODE:
      return {
        ...state,
        isLoading: true,
      };
    case codeActions.VERIFY_CODE_SUCCESS:
      return {
        ...state,
        accessCode: payload,
        isLoading: false,
      };
    case codeActions.VERIFY_CODE_ERROR:
      return {
        ...state,
        isLoading: false,
        errorMessage: payload,
      };
    case codeActions.ACCESS_OF_CODE:
      return {
        ...state,
        isLoading: true,
      };
    case codeActions.ACCESS_OF_CODE_SUCCESS:
      return {
        ...state,
        accessCode: payload,
        isLoading: false,
      };
    case codeActions.ACCESS_OF_CODE_ERROR:
      return {
        ...state,
        isLoading: false,
        errorMessage: payload,
      };
    default: {
      return state;
    }
  }
};
