import React from "react";
import { useParams } from "react-router-dom";

import "../../styles/imageView.css";

import { useTareas } from "../../context/providers/tareasContext";
import OldComments from "./OldComments";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAnglesLeft } from "@fortawesome/free-solid-svg-icons";

const ImageView = () => {
  const { tareaRand, tareaId, isLoading } = useTareas();

  const { url } = useParams();
  return (
    <main className="main-imageview">
      <section className="section-image-view">
        <div className="img-imageview">
          <img src={decodeURIComponent(url)} alt="Imagen" />
        </div>
        <div className="text-description-imageView-container">
          <p className="text-description-imageView">
            {tareaRand?.tareaObtenida?.description}
          </p>
        </div>

        {!isLoading ? (
          <OldComments tarea={tareaId} />
        ) : (
          <>
            <p>Cargando...</p>
          </>
        )}
        <button
          className="buttom-back-details"
          onClick={() => {
            window.history.back();
          }}
        >
          <FontAwesomeIcon icon={faAnglesLeft} />
        </button>
      </section>
    </main>
  );
};

export default ImageView;
