import React from "react";

const OdsActivity10 = () => {
  return (
    <div className="ods-activities-container">
      <h2>ODS ACTIVIDAD 10:</h2>
      <div className="ods-activity-card">
        <div className="ods-activity-image">
          <img
            src="https://res.cloudinary.com/dd8a6qc45/image/upload/v1722645231/Volunteers/assets/ODS/3_tqqywb.png"
            alt="Numero ODS"
          />
        </div>
        <p>
          <span>ODS 3: Salud y bienestar: (Meta 3.9) </span> <br />
          La eliminación de residuos sólidos reduce el riesgo de enfermedades y
          mejora la calidad de vida al disminuir la contaminación y mejorar las
          condiciones higiénicas del entorno.
        </p>
      </div>
      <div className="ods-activity-card">
        <div className="ods-activity-image">
          <img
            src="https://res.cloudinary.com/dd8a6qc45/image/upload/v1722645230/Volunteers/assets/ODS/6_mwuiqs.png"
            alt="Numero ODS"
          />
        </div>
        <p>
          <span>ODS 6: Agua limpia y saneamiento: (Meta 6.3) </span> <br />
          La limpieza de residuos sólidos en áreas rurales y urbanas previene
          que estos desechos lleguen a cuerpos de agua, mejorando así la calidad
          del agua y contribuyendo a un entorno más seguro y limpio.
        </p>
      </div>
      <div className="ods-activity-card">
        <div className="ods-activity-image">
          <img
            src="https://res.cloudinary.com/dd8a6qc45/image/upload/v1722645225/Volunteers/assets/ODS/11_qyelnc.png"
            alt="Numero ODS"
          />
        </div>
        <p>
          <span>ODS 11: Ciudades y comunidades sostenibles: (Meta 11.6) </span>{" "}
          <br />
          Mantener espacios urbanos y rurales limpios y libres de residuos
          sólidos mejora la calidad de vida de los habitantes, promueve un
          entorno más seguro y saludable, y hace que las ciudades y comunidades
          sean más sostenibles y atractivas.
        </p>
      </div>
      <div className="ods-activity-card">
        <div className="ods-activity-image">
          <img
            src="https://res.cloudinary.com/dd8a6qc45/image/upload/v1722645225/Volunteers/assets/ODS/12_totmyp.png"
            alt="Numero ODS"
          />
        </div>
        <p>
          <span>ODS 12: Producción y consumo responsables: (Meta 12.5) </span>{" "}
          <br />
          La limpieza de residuos sólidos fomenta prácticas de reciclaje y
          reutilización, contribuyendo a una gestión más sostenible de los
          desechos y a la reducción de la cantidad de residuos que terminan en
          vertederos o contaminan el medio ambiente.
        </p>
      </div>
      <div className="ods-activity-card">
        <div className="ods-activity-image">
          <img
            src="https://res.cloudinary.com/dd8a6qc45/image/upload/v1722645225/Volunteers/assets/ODS/13_tmtnkh.png"
            alt="Numero ODS"
          />
        </div>
        <p>
          <span>ODS 13: Acción por el clima: (Meta 13.1) </span> <br />
          La gestión adecuada de residuos sólidos reduce la contaminación que
          contribuye al cambio climático. Además, mantener los espacios libres
          de residuos sólidos puede mejorar la resiliencia de las comunidades
          frente a desastres naturales, ya que los desechos pueden obstruir los
          sistemas de drenaje y aumentar la vulnerabilidad a inundaciones.
        </p>
      </div>
      <div className="ods-activity-card">
        <div className="ods-activity-image">
          <img
            src="https://res.cloudinary.com/dd8a6qc45/image/upload/v1722645225/Volunteers/assets/ODS/14_mbd8kd.png"
            alt="Numero ODS"
          />
        </div>
        <p>
          <span>ODS 14: Vida submarina: (Meta 14.1) </span> <br />
          La limpieza de residuos sólidos, especialmente plásticos y otros
          materiales no biodegradables, ayuda a prevenir la contaminación de
          ríos, lagos y océanos, protegiendo la vida marina y los ecosistemas
          acuáticos.
        </p>
      </div>
      <div className="ods-activity-card">
        <div className="ods-activity-image">
          <img
            src="https://res.cloudinary.com/dd8a6qc45/image/upload/v1722645225/Volunteers/assets/ODS/15_hd4qmx.png"
            alt="Numero ODS"
          />
        </div>
        <p>
          <span>
            ODS 15: Vida de ecosistemas terrestres: (Meta 15.1, 15.5){" "}
          </span>{" "}
          <br />
          La eliminación de residuos sólidos protege los hábitats terrestres y
          la biodiversidad, evitando la degradación del suelo y la contaminación
          que pueden afectar negativamente a la flora y fauna local.
        </p>
      </div>
      <div className="ods-activity-card">
        <div className="ods-activity-image">
          <img
            src="https://res.cloudinary.com/dd8a6qc45/image/upload/v1722645225/Volunteers/assets/ODS/17_e8x3cm.png"
            alt="Numero ODS"
          />
        </div>
        <p>
          <span>ODS 17: Alianzas para lograr los objetivos: (Meta 17.16) </span>{" "}
          <br />
          Las acciones para limpiar residuos sólidos a menudo involucran la
          colaboración entre gobiernos, organizaciones no gubernamentales,
          empresas y comunidades locales. Estas alianzas son esenciales para
          movilizar recursos, compartir conocimientos y tecnología, y promover
          prácticas sostenibles que contribuyan al logro de los ODS.
        </p>
      </div>
    </div>
  );
};

export default OdsActivity10;
