import React from "react";

const OdsActivity13 = () => {
  return (
    <div className="ods-activities-container">
      <h2>ODS ACTIVIDAD 13:</h2>
      <div className="ods-activity-card">
        <div className="ods-activity-image">
          <img
            src="https://res.cloudinary.com/dd8a6qc45/image/upload/v1722645231/Volunteers/assets/ODS/3_tqqywb.png"
            alt="Numero ODS"
          />
        </div>
        <p>
          <span>ODS 3. Salud y bienestar: (Meta 3.4) </span> <br />
          El bienestar animal está vinculado al bienestar humano, es por esto
          que, promover el trato humanitario a los animales puede contribuir a
          una mejor salud mental y emocional de las personas, ya que el maltrato
          animal puede tener efectos psicológicos negativos en quienes lo
          presencian o participan en él. Además, evitar el maltrato animal en la
          cría y manejo de animales de compañía puede reducir la incidencia de
          zoonosis y otras enfermedades transmitidas de animales a humanos.
        </p>
      </div>
      <div className="ods-activity-card">
        <div className="ods-activity-image">
          <img
            src="https://res.cloudinary.com/dd8a6qc45/image/upload/v1722645225/Volunteers/assets/ODS/12_totmyp.png"
            alt="Numero ODS"
          />
        </div>
        <p>
          <span>
            ODS 12. Producción y consumo responsables: (Meta 12.2, 12.8){" "}
          </span>{" "}
          <br />
          Sensibilizar sobre el maltrato animal promueve prácticas de consumo
          más responsables y sostenibles, como la elección de productos de
          origen animal que provienen de sistemas de producción ética y
          sostenible. Esto puede conducir a una gestión más eficiente y ética de
          los recursos naturales, disminuyendo la explotación y el abuso de los
          animales.
        </p>
      </div>
      <div className="ods-activity-card">
        <div className="ods-activity-image">
          <img
            src="https://res.cloudinary.com/dd8a6qc45/image/upload/v1722645225/Volunteers/assets/ODS/14_mbd8kd.png"
            alt="Numero ODS"
          />
        </div>
        <p>
          <span>ODS 14: Vida submarina: (Meta 14.1, 14.4) </span> <br />
          Sensibilizar sobre el maltrato animal incluye abordar prácticas de
          pesca destructivas y sobreexplotación de recursos marinos, fomentando
          métodos de pesca sostenibles y el respeto por la vida marina. Esto
          ayuda a mantener los ecosistemas marinos saludables y a prevenir la
          degradación de los hábitats marinos.
        </p>
      </div>
      <div className="ods-activity-card">
        <div className="ods-activity-image">
          <img
            src="https://res.cloudinary.com/dd8a6qc45/image/upload/v1722645225/Volunteers/assets/ODS/15_hd4qmx.png"
            alt="Numero ODS"
          />
        </div>
        <p>
          <span>
            ODS 15: Vida de ecosistemas terrestres: (Meta 15.5, 15.7){" "}
          </span>{" "}
          <br />
          Apoyar acciones en contra del maltrato animal contribuye directamente
          a la protección de la biodiversidad y la conservación de los
          ecosistemas terrestres. Combatir el maltrato y la explotación de
          animales silvestres ayuda a preservar las especies y mantener el
          equilibrio de los ecosistemas.
        </p>
      </div>
    </div>
  );
};

export default OdsActivity13;
