import React from "react";
import Enter from "../components/ui/Enter";
import ChatBotRetune from "../components/Dashboard/ChatBotRetune";
import { useUser } from "../context/providers/userContext";

import("../styles/index.css");

const Index = () => {

  const {user} = useUser();
  const screenWidth = window.innerWidth;

  let videoToShow = "";

  if (screenWidth <= 720) {
    videoToShow =
      "https://res.cloudinary.com/dd8a6qc45/video/upload/v1692646415/Volunteers/168085720_waq5mg.mp4";
  } else if (screenWidth > 720) {
    videoToShow =
      "https://res.cloudinary.com/dd8a6qc45/video/upload/v1692575929/Volunteers/168085_ocia7k.mp4";
  }

  return (
    <main className="homepage-main">
      <Enter />
      <div className="frontpage">
        <video src={videoToShow} autoPlay loop muted preload="auto" />
      </div>
      <h1 className="welcome">BIENVENIDOS!</h1>
      <section className="section-home">
        {/* <iframe
      src="https://retune.so/share/chat/11ee5794-2bd8-34f0-899f-036ecb8650de/widget"
      width="550"
      height="700"
      // style="border:0 background:white"
      title="chat-bot"
    ></iframe> */}
        <article>
          <div className="article-home">
            <h2>OBJETIVOS PRINCIPALES DE UN COACH AMBIENTALISTA</h2>
            <p>
              El coach ambientalista es como el superhéroe del planeta,
              motivando a todos a reducir su huella ecológica y proteger nuestro
              hogar. No solo guía a las personas hacia un cambio positivo, sino
              que también lidera acciones voluntarias en momentos de crisis
              ambiental. ¡Es hora de unirnos y salvar el planeta juntos!
            </p>
          </div>
          <div className="article-home">
            <h2>
              ¿COMO SE PUEDE DESEMPEÑAR UN COACH AMBIENTALISTA EN LA SOCIEDAD?
            </h2>
            <p>
              Imagina a un coach ambientalista como el guerrero verde que
              trabaja con todos: individuos, empresas, escuelas, ¡incluso tu
              comunidad! Su misión es empoderar a todos para tomar acciones que
              protejan el medio ambiente y mejoren nuestras vidas. ¡Es el héroe
              que todos necesitamos para un futuro más verde y brillante!
            </p>
          </div>
        </article>
      </section>
    </main>
  );
};
export default Index;
