import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Toaster } from "react-hot-toast";

import { TareasProvider } from "./context/providers/tareasContext";
import { UserProvider } from "./context/providers/userContext";
import { CommentsProvider } from "./context/providers/commentsContext";
import { CodeProvider } from "./context/providers/codeContext";

import Navbar from "./components/ui/Navbar";
import AccountFree from "./pages/accountFree";
import Account from "./pages/account";
import Index from "./pages";
import Content from "./pages/content/content";
import Login from "./pages/forms/login";
import SignUp from "./pages/forms/signUp";
import Nosotros from "./pages/nosotros";
import Details from "./pages/content/details";
import Perfil from "./pages/forms/Perfil";
import Footer from "./components/ui/Footer";
import CertificadoPage from "./pages/CertificadoPage";
import DashboardPage from "./pages/Dashboard/DashboardPage";

import {
  AdminRoute,
  PrivateRoute,
  PublicRoute,
} from "./components/PrivateRoute";
import AdminPage from "./pages/AdminPage";
import AdminUserPage from "./pages/adminPage/AdminUserPage";
import ImageView from "./components/ui/ImageView";
import ActivitiesAdmin from "./pages/adminPage/ActivitiesAdmin";
import { ActivitiesProvider } from "./context/providers/activitiesContext";
import ActivityAdmin from "./pages/forms/ActivityAdmin";
import Profiles from "./pages/Profiles";
import ResetPassword from "./pages/forms/ResetPassword";
import SendEmailPassword from "./pages/forms/SendEmailPassword";
import CodeAdmin from "./pages/CodeAdmin";
import ExamDetails from "./pages/content/examDetails";
import ConfigPerfil from "./pages/ConfigPerfil";
import CertificadoVolunteers from "./pages/CertificadoVolunteers";
// import Chat from "./pages/Chat";
import { MessagesProvider } from "./context/providers/messagesContext";
import { io } from "socket.io-client";
import { apiIoURL } from "./api/apiConfig";
import Ods from "./pages/ods";
import OdsActivity1 from "./components/ui/Ods/OdsActivity1";
import OdsActivity2 from "./components/ui/Ods/OdsActivity2";
import OdsActivity3 from "./components/ui/Ods/OdsActivity3";
import OdsActivity4 from "./components/ui/Ods/OdsActivity4";
import OdsActivity5 from "./components/ui/Ods/OdsActivity5";
import OdsActivity6 from "./components/ui/Ods/OdsActivity6";
import OdsActivity7 from "./components/ui/Ods/OdsActivity7";
import OdsActivity8 from "./components/ui/Ods/OdsActivity8";
import OdsActivity9 from "./components/ui/Ods/OdsActivity9";
import OdsActivity10 from "./components/ui/Ods/OdsActivity10";
import OdsActivity11 from "./components/ui/Ods/OdsActivity11";
import OdsActivity12 from "./components/ui/Ods/OdsActivity12";
import OdsActivity13 from "./components/ui/Ods/OdsActivity13";
import OdsActivity14 from "./components/ui/Ods/OdsActivity14";
import OdsActivity15 from "./components/ui/Ods/OdsActivity15";
import OdsActivity16 from "./components/ui/Ods/OdsActivity16";
import OdsActivity17 from "./components/ui/Ods/OdsActivity17";
import OdsActivity18 from "./components/ui/Ods/OdsActivity18";
import Presentation from "./components/Presentation";

// const socket = io("http://localhost:5200");

const socket = io(apiIoURL, {
  reconnection: true,
  reconnectionAttempts: 10,
  reconnectionDelay: 2000,
  reconnectionDelayMax: 5000,
});

function App() {
  return (
    <Router>
      <UserProvider>
        <TareasProvider>
          <CommentsProvider>
            <ActivitiesProvider>
              <CodeProvider>
                <MessagesProvider>
                  <Navbar />
                  <Routes>
                    <Route path="*" element={<Index />} />
                    <Route path="/index" element={<Index />} />
                    <Route path="/" element={<Index />} />
                    <Route path="/content" element={<Content />} />
                    <Route path="/nosotros" element={<Nosotros />} />
                    <Route path="/image/:url" element={<ImageView />} />
                    <Route path="/dashboard" element={<DashboardPage />} />
                    <Route path="/reset-password" element={<ResetPassword />} />
                    <Route path="/presentation" element={<Presentation />} />
                    <Route
                      path="/send-password"
                      element={<SendEmailPassword />}
                    />
                    <Route path="/ods" element={<Ods />} />

                    <Route element={<PublicRoute />}>
                      <Route path="/login" element={<Login />} />
                      <Route path="/signup" element={<SignUp />} />
                    </Route>

                    <Route element={<PrivateRoute />}>
                      <Route path="/details/:id" element={<Details />} />
                      <Route
                        path="/certificado/:id"
                        element={<CertificadoPage />}
                      />
                      <Route
                        path="/certificado-volunteers/:id"
                        element={<CertificadoVolunteers />}
                      />
                      <Route path="/update-perfil/:id" element={<Perfil />} />
                      <Route path="/account-free/" element={<AccountFree />} />
                      <Route path="/account-pro" element={<Account />} />
                      <Route
                        path="/profile/:id"
                        element={<Profiles socket={socket} />}
                      />
                      <Route path="/exam/:id" element={<ExamDetails />} />
                      <Route path="/config-perfil" element={<ConfigPerfil />} />
                      {/* <Route path="/chat" element={<Chat />} /> */}

                      {/* ---> Start ODS activities */}
                      <Route
                        path="account-free/ods-activity-1"
                        element={<OdsActivity1 />}
                      />
                      <Route
                        path="account-free/ods-activity-2"
                        element={<OdsActivity2 />}
                      />
                      <Route
                        path="account-free/ods-activity-3"
                        element={<OdsActivity3 />}
                      />
                      <Route
                        path="account-free/ods-activity-4"
                        element={<OdsActivity4 />}
                      />
                      <Route
                        path="account-free/ods-activity-5"
                        element={<OdsActivity5 />}
                      />
                      <Route
                        path="account-free/ods-activity-6"
                        element={<OdsActivity6 />}
                      />
                      <Route
                        path="account-free/ods-activity-7"
                        element={<OdsActivity7 />}
                      />
                      <Route
                        path="account-free/ods-activity-8"
                        element={<OdsActivity8 />}
                      />
                      <Route
                        path="account-free/ods-activity-9"
                        element={<OdsActivity9 />}
                      />
                      <Route
                        path="account-free/ods-activity-10"
                        element={<OdsActivity10 />}
                      />
                      <Route
                        path="account-free/ods-activity-11"
                        element={<OdsActivity11 />}
                      />
                      <Route
                        path="account-free/ods-activity-12"
                        element={<OdsActivity12 />}
                      />
                      <Route
                        path="account-free/ods-activity-13"
                        element={<OdsActivity13 />}
                      />
                      <Route
                        path="account-free/ods-activity-14"
                        element={<OdsActivity14 />}
                      />
                      <Route
                        path="account-free/ods-activity-15"
                        element={<OdsActivity15 />}
                      />
                      <Route
                        path="account-free/ods-activity-16"
                        element={<OdsActivity16 />}
                      />
                      <Route
                        path="account-free/ods-activity-17"
                        element={<OdsActivity17 />}
                      />
                      <Route
                        path="account-free/ods-activity-18"
                        element={<OdsActivity18 />}
                      />
                      {/* ---> End ODS activities */}
                    </Route>

                    <Route element={<AdminRoute />}>
                      <Route path="/admin-panel" element={<AdminPage />} />
                      <Route
                        path="/admin-panel/account/:id"
                        element={<AdminUserPage />}
                      />
                      <Route
                        path="/activities-admin"
                        element={<ActivitiesAdmin />}
                      />
                      <Route path="/codes-admin" element={<CodeAdmin />} />
                      <Route path="/new-activity" element={<ActivityAdmin />} />
                      <Route
                        path="/edit-activity/:id"
                        element={<ActivityAdmin />}
                      />
                    </Route>
                  </Routes>
                  <Footer socket={socket} />
                  <Toaster />
                </MessagesProvider>
              </CodeProvider>
            </ActivitiesProvider>
          </CommentsProvider>
        </TareasProvider>
        <Toaster />
      </UserProvider>
    </Router>
  );
}

export default App;
