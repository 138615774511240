import React from "react";

const OdsActivity11 = () => {
  return (
    <div className="ods-activities-container">
      <h2>ODS ACTIVIDAD 11:</h2>
      <div className="ods-activity-card">
        <div className="ods-activity-image">
          <img
            src="https://res.cloudinary.com/dd8a6qc45/image/upload/v1722645231/Volunteers/assets/ODS/3_tqqywb.png"
            alt="Numero ODS"
          />
        </div>
        <p>
          <span>ODS 3: Salud y Bienestar: (Meta 3.9) </span> <br />
          Reducir el uso de plásticos desechables disminuye la exposición a
          productos químicos que pueden ser perjudiciales para la salud humana.
          Los plásticos desechables pueden liberar sustancias tóxicas,
          especialmente cuando se calientan o se degradan.
        </p>
      </div>
      <div className="ods-activity-card">
        <div className="ods-activity-image">
          <img
            src="https://res.cloudinary.com/dd8a6qc45/image/upload/v1722645230/Volunteers/assets/ODS/6_mwuiqs.png"
            alt="Numero ODS"
          />
        </div>
        <p>
          <span>ODS 6: Agua Limpia y Saneamiento: (Meta 6.3) </span> <br />
          Menos plásticos desechables significa menos desechos plásticos que
          pueden terminar contaminando las fuentes de agua. Los microplásticos y
          los residuos plásticos pueden contaminar ríos, lagos y océanos,
          afectando la calidad del agua y la salud de los ecosistemas acuáticos.
        </p>
      </div>
      <div className="ods-activity-card">
        <div className="ods-activity-image">
          <img
            src="https://res.cloudinary.com/dd8a6qc45/image/upload/v1722645225/Volunteers/assets/ODS/11_qyelnc.png"
            alt="Numero ODS"
          />
        </div>
        <p>
          <span>ODS 11: Ciudades y Comunidades Sostenibles: (Meta 11.6) </span>{" "}
          <br />
          Evitar el uso de plásticos desechables contribuye a una mejor gestión
          de los desechos sólidos en las ciudades. Disminuye la cantidad de
          basura generada y reduce la presión sobre los sistemas de gestión de
          residuos, contribuyendo a un entorno urbano más limpio y saludable.
        </p>
      </div>
      <div className="ods-activity-card">
        <div className="ods-activity-image">
          <img
            src="https://res.cloudinary.com/dd8a6qc45/image/upload/v1722645225/Volunteers/assets/ODS/12_totmyp.png"
            alt="Numero ODS"
          />
        </div>
        <p>
          <span>
            ODS 12: Producción y Consumo Responsables: (Meta 12.5, 12.8){" "}
          </span>{" "}
          <br />
          Organizar eventos sin plásticos desechables fomenta la adopción de
          prácticas sostenibles y responsables. Enseña a los participantes sobre
          la importancia de reducir, reutilizar y reciclar, promoviendo un
          cambio de comportamiento hacia un consumo más consciente y
          responsable.
        </p>
      </div>
      <div className="ods-activity-card">
        <div className="ods-activity-image">
          <img
            src="https://res.cloudinary.com/dd8a6qc45/image/upload/v1722645225/Volunteers/assets/ODS/13_tmtnkh.png"
            alt="Numero ODS"
          />
        </div>
        <p>
          <span>ODS 13: Acción por el Clima: (Meta 13.3) </span> <br />
          Reducir el uso de plásticos desechables contribuye a la reducción de
          las emisiones de gases de efecto invernadero asociadas con la
          producción y la incineración de plásticos. Además, eventos sin
          plásticos pueden servir como plataformas educativas para sensibilizar
          sobre los impactos del cambio climático y la necesidad de adoptar
          prácticas sostenibles.
        </p>
      </div>
      <div className="ods-activity-card">
        <div className="ods-activity-image">
          <img
            src="https://res.cloudinary.com/dd8a6qc45/image/upload/v1722645225/Volunteers/assets/ODS/14_mbd8kd.png"
            alt="Numero ODS"
          />
        </div>
        <p>
          <span>ODS 14: Vida Submarina: (Meta 14.1) </span> <br />
          Al evitar el uso de plásticos desechables, se reduce la cantidad de
          desechos plásticos que pueden llegar a los océanos y mares. Esto ayuda
          a proteger la vida marina y a mantener la salud de los ecosistemas
          marinos, que son cruciales para la biodiversidad y la pesca
          sostenible.
        </p>
      </div>
      <div className="ods-activity-card">
        <div className="ods-activity-image">
          <img
            src="https://res.cloudinary.com/dd8a6qc45/image/upload/v1722645225/Volunteers/assets/ODS/15_hd4qmx.png"
            alt="Numero ODS"
          />
        </div>
        <p>
          <span>ODS 15: Vida de Ecosistemas Terrestres: (Meta 15.1) </span>{" "}
          <br />
          Al reducir los residuos plásticos, se protege la fauna terrestre que
          puede verse afectada por la ingestión o enredo con plásticos
          desechables, contribuyendo a la preservación de la biodiversidad y los
          ecosistemas terrestres.
        </p>
      </div>
      <div className="ods-activity-card">
        <div className="ods-activity-image">
          <img
            src="https://res.cloudinary.com/dd8a6qc45/image/upload/v1722645225/Volunteers/assets/ODS/17_e8x3cm.png"
            alt="Numero ODS"
          />
        </div>
        <p>
          <span>ODS 17: Alianzas para lograr los objetivos: (Meta 17.17) </span>{" "}
          <br />
          Organizar eventos sin plásticos desechables puede involucrar la
          colaboración entre diferentes actores, como organizaciones
          ambientales, empresas, gobiernos locales y comunidades. Estas alianzas
          pueden fortalecer los esfuerzos conjuntos para promover prácticas
          sostenibles y alcanzar los objetivos de desarrollo sostenible.
        </p>
      </div>
    </div>
  );
};

export default OdsActivity11;
