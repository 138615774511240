import React, { useEffect, useState } from "react";
import { useUser } from "../context/providers/userContext";
import { useParams } from "react-router-dom";

import { Spinner } from "../components/ui/Spinner";
import { useActivities } from "../context/providers/activitiesContext";
import ActivityProfiles from "../components/ActivityProfiles";
import SendMessagesPerfil from "../components/SendMessagePerfil";

import "../styles/profiles.css";
import { useMessages } from "../context/providers/messagesContext";

const Profiles = ({socket}) => {
  const { userId, getUserTareas, userTareas, isLoading, getUserId } = useUser();

  const { isLoading: isLoadingActivities } = useActivities();

  const { messagesUserToUser, viewChatController } = useMessages();

  const [showChat, setShowChat] = useState(false);

  const params = useParams();

  const handleChat = async () => {
    if (showChat) {
      setShowChat(false);
    } else {
      await messagesUserToUser(params.id);
      setShowChat(true);
    }
  };

  const chatView = () => {};

  useEffect(() => {
    const start = async () => {
      await getUserTareas(params.id);
      await getUserId(params.id);
    };
    start();
  }, [params]);

  return (
    <main className="main-accountfree ">
      {isLoading ? (
        Spinner
      ) : (
        <>
          <div className="accountfree-container">
            <section className="head-perfil">
              <div className="img-fondo">
                <img
                  src="https://images.pexels.com/photos/1324803/pexels-photo-1324803.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                  alt="Imagen de fondo"
                />
              </div>
              <div className="img-perfil-card">
                <div className="img-perfil">
                  <img
                    src={
                      userId?.image && userId?.image?.url
                        ? userId?.image?.url
                        : "https://res.cloudinary.com/dd8a6qc45/image/upload/v1690634531/Volunteers/perfilImage/account_e6qpmh.svg"
                    }
                    alt="Foto de perfil"
                  />
                  <h1 className="title-name-user-perfil">{userId?.name}</h1>
                </div>
                {/* <div className="send-message-all-container">
                  <button
                    className="button-send-message-user send-message-container"
                    onClick={() => {
                      handleChat();
                    }}
                  >
                    Enviar Mensaje
                  </button>
                  {showChat && (
                    <SendMessagesPerfil userChat={userId} socket={socket}/>
                    )}
                </div> */}
              </div>
            </section>

            <section className="cards">
              {isLoadingActivities ? (
                Spinner
              ) : (
                <>
                  <article className="activity">
                    <div key="activities-container">
                      {userTareas.map((userTarea) => (
                        <div key={userTarea._id}>
                          <div>
                            {userTarea.image?.url && userTarea.number !== 18 ? (
                              <div
                                className="card-content-other-container activity-id"
                                id="activity-id"
                              >
                                <ActivityProfiles tarea={userTarea} />
                              </div>
                            ) : (
                              <></>
                            )}
                          </div>
                        </div>
                      ))}
                    </div>
                  </article>
                </>
              )}
            </section>
          </div>
        </>
      )}
    </main>
  );
};

export default Profiles;
